import validate from 'validate.js';

//constraints
const required = message => ({presence: {message}});

const email = message => ({
  ...required(message),
  email: {message: 'Formato email non valido'},
});

const passwordConstraint = message => ({
  ...required(message),
  length: {
    minimum: 8,
    message: 'La password deve contenere almeno 8 caratteri',
  },
});

const repeatPasswordConstraint = (message, fieldName = 'password') => ({
  ...required(message),
  equality: {
    attribute: fieldName, //name of another field this one must be equal to
    message: 'Conferma password non coincide con la password inserita',
  },
});

const contactInfoContraints = nationality => {
  const standardConstraints = {
    firstName: required('Inserisci il nome'),
    lastName: required('Inserisci il cognome'),
    gender: required('Scegli il genere'),
    birthDate: required('Inserisci la data di nascita'),
    fiscalCode: required('Inserisci il codice fiscale'),
    email1: email("Inserisci l'email"),
    mobilePhone: required('Inserisci il numero di cellulare'),
  };

  if (nationality === 'Italiana') {
    return {
      ...standardConstraints,
      birthPlace: required('Inserisci la città di nascita'),
    };
  }

  return {
    ...standardConstraints,
    birthNation: required('Inserisci la nazione di nascita'),
    foreignBirthCity: required('Inserisci la città di nascita'),
  };
};

//validation functions
export const validateLogin = data =>
  validate(data, {username: required("Inserisci l'username")}, {fullMessages: false});

export const validateRegister = data => {
  const constraints = {
    ...contactInfoContraints(data.nationality),
    password: passwordConstraint('Inserisci la password'),
    repeatPassword: repeatPasswordConstraint(
      'Inserisci la conferma della password'
    ),
    privacyAccepted: required(
      'È necessario accettare le condizioni per procedere con la registrazione'
    ),
    marketingAccepted: required(
      'È necessario scegliere una opzione per procedere con la registrazione'
    ),
  };

  return validate(data, constraints, {fullMessages: false});
};

export const validateFiscalCode = data => {
  const constraints = {
    firstName: required('Inserisci il nome'),
    lastName: required('Inserisci il cognome'),
    gender: required('Inserisci il genere'),
    birthDate: required('Inserisci la data di nascita'),
    birthPlace: required('Inserisci la città di nascita'),
  };

  return validate(data, constraints, {fullMessages: false});
};

export const validatePasswordChange = data => {
  const constraints = {
    oldPassword: required('Inserisci la password attuale'),
    newPassword: passwordConstraint('Inserisci la password nuova'),
    confirmNewPassword: repeatPasswordConstraint(
      'Inserisci la conferma della password',
      'newPassword'
    ),
  };

  return validate(data, constraints, {fullMessages: false});
};

export const validateUpdateMember = data => {
  const constraints = {
    ...contactInfoContraints(data.nationality),
    email2: {email: {message: 'Formato email non valido'}},
    privacyAccepted: required(
      'È necessario accettare le condizioni per procedere con la registrazione'
    ),
    marketingAccepted: required(
      'È necessario scegliere una opzione per procedere con la registrazione'
    ),
  };
  return validate(data, constraints, {fullMessages: false});
};

export const validateUpdateParticipant = data => {
  const constraints = {
    ...contactInfoContraints(data.nationality),
    email2: {email: {message: 'Formato email non valido'}},
    //mobile phone not required for participants
    mobilePhone: undefined,
  };

  return validate(data, constraints, {fullMessages: false});
};
