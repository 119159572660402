import React from 'react';
import styled from 'styled-components';

import {Typography} from '@material-ui/core';

const Text = styled(Typography).attrs({variant: 'body1', component: 'span'})``;

const Bold = styled(Text)`
  font-weight: 600;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.img.attrs({
  alt: 'Giocamondo Logo',
  src: require('../assets/giocamondo-logo.png'),
})`
  height: 20px;
  margin-right: 8px;
`;

const TitleComponent = (
  <Title>
    <LogoImage />
    <Text>
      <Bold>Club</Bold> da Giocamondo
    </Text>
  </Title>
);

export const steps = [
  {
    target: '#tour-step-1',
    title: TitleComponent,
    content: (
      <Text>
        Qui potrai visualizzare i punti accumulati
      </Text>
    ),
    disableBeacon: true, //makes tour start immediately
    placement: 'auto',
  },
  {
    target: '#tour-step-2',
    title: TitleComponent,
    content: <Text>Qui puoi vedere il tuo livello di appartenenza</Text>,
    placement: 'auto',
  },
  {
    target: '#tour-step-3',
    title: TitleComponent,
    content: (
      <Text>
        In quest'area potrai visualizzare e modificare{' '}
        <Bold>i tuoi dati, i consensi</Bold> da te inseriti e{' '}
        <Bold>personalizzare la tua password</Bold> di accesso.
      </Text>
    ),
    placement: 'auto',
  },
  {
    target: '#tour-step-4',
    title: TitleComponent,
    content: (
      <Text>
        Questa è la sezione in qui potrai vedere tutte le tue prenotazioni,
        <Bold>
          controllare i dati, lo stato di avanzamento ed effettuare alcune
          modifiche.
        </Bold>
      </Text>
    ),
    placement: 'auto',
  },
  {
    target: '#tour-step-5',
    title: TitleComponent,
    content: (
      <Text>
        GiocamondoClub ti mette a disposizione una sezione dedicata per{' '}
        <Bold>visualizzare lo stato dei tuoi pagamenti.</Bold>
      </Text>
    ),
    placement: 'auto',
  },
  {
    target: '#tour-step-6',
    title: TitleComponent,
    content: (
      <Text>
        Un archivio di tutta la tua{' '}
        <Bold>
          documentazione amministrativa, sempre consultabile e scaricabile.
        </Bold>
      </Text>
    ),
    placement: 'auto',
  },
];
