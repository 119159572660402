import React, {useState, useEffect, useCallback} from 'react';
import PracticeItem from '../components/PracticeItem';
import DocumentDetails from '../components/DocumentDetails';
import {useUser} from '../utils/UserSession';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Container,
  EmptyText,
  ErrorAlert,
  Spinner,
} from '../utils/commonStyledComponents';
import {
  fetchPracticesDocuments,
  fetchDocumentTypologies,
  startSignProcedure,
  endSignProcedure,
  downloadSignedDocument,
} from '../utils/api';
import config from '../utils/config';
import ModuloB from './ModuloB';
import {format, parseISO} from 'date-fns';
import {filterPracticesByYear, getPracticeYears} from "../utils/commonFunctions";
import YearItem from "../components/YearItem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Documents = () => {
  const [practices, setPractices] = useState([]);
  const [documentTypology, setDocumentTypology] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [signData, setSignData] = useState(null);
  const [moduloB, setModuloB] = useState(null);

  const refresh = useCallback(async () => {
    try {
      const data = await fetchPracticesDocuments();
      // console.log("PRACTICE DATA");
      // console.log(data);
      setPractices(data);
    } catch (e) {
      setShowErrorMessage(true);
    }
    try {
      const data = await fetchDocumentTypologies();
      setDocumentTypology(data);
      // console.log("IDDU001");
      // console.log(data);
    } catch (e) {
      setShowErrorMessage(true);
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (signData === null) {
      return;
    }

    const timer = setInterval(() => {
      endSignProcedure(signData.documentId, signData.procedureId).then(() => {
        setSignData(null);
        refresh();
        clearInterval(timer);
      });
    }, 2000);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue =
        'Il processo di firma non è ancora completato. Sei sicuro di voler uscire?';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearInterval(timer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [refresh, signData]);

  const startSignProcess = async documentId => {
    const response = await startSignProcedure(documentId);

    setSignData({documentId, ...response});
  };

  const downloadSigned = async documentId => {
    const response = await downloadSignedDocument(documentId);

    const blob = new Blob([response], {
      type: 'application/pdf',
    });

    const obj = URL.createObjectURL(blob);

    window.open(obj);
    // URL.revokeObjectURL(obj);
  };

  const endSignProcess = async () => {
    await endSignProcedure(signData.documentId, signData.procedureId).catch(
      console.error
    );
    setSignData(null);
    refresh();
  };

  if (showErrorMessage) {
    return <ErrorAlert>Si è verificato un errore di caricamento</ErrorAlert>;
  }

  if (!(practices && documentTypology)) {
    return <Spinner />;
  }

  const hasDocuments = practices.some(el => el.documents.length);
  if (!hasDocuments) {
    return <EmptyText>Nessun documento disponibile</EmptyText>;
  }

    const years = getPracticeYears(practices);

    return (
        <Container>
            {years.map(y => {
                const filteredPractices = filterPracticesByYear(practices, y);
                return <YearItem key={y} year={y}>
                    {filteredPractices.map((practice, index) => (
                        <PracticeItem practice={practice} key={index}>
                            <DocumentDetails
                                documents={practice.documents}
                                id={practice.id}
                                refresh={refresh}
                                documentTypologyArray={documentTypology}
                                startSignProcess={startSignProcess}
                                downloadSigned={downloadSigned}
                                startModuleB={documentId =>
                                    setModuloB({
                                        documentId,
                                        division: practice.productDivisionId,
                                        participant: {
                                            ...practice.participants[0],
                                            relation: practice.partOneRelWithBuyer,
                                            holidayCentre: practice.packageTitle,
                                            dates: `${format(
                                                parseISO(practice.originalPackagePeriodFrom),
                                                'dd/MM/Y'
                                            )} - ${format(
                                                parseISO(practice.originalPackagePeriodTo),
                                                'dd/MM/Y'
                                            )}`,
                                        },
                                    })
                                }
                            />
                        </PracticeItem>
                    ))}
                </YearItem>
            })}
            <Dialog
                className="yousign-dialog"
                fullScreen
                open={signData !== null}
                onClose={endSignProcess}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <Button
                            className="btn-close"
                            autoFocus
                            color="inherit"
                            onClick={endSignProcess}
                        >
                            Chiudi
                        </Button>
                    </Toolbar>
                </AppBar>
                {signData && (
                    <iframe
                        style={{width: '100%', paddingTop: '64px', height: '94%'}}
                        src={config.yousignUrl + signData.signatureUrl}
                        title="Firma digitale"
                    ></iframe>
                )}
            </Dialog>

      {moduloB && (
        <ModuloB
          division={moduloB.division}
          documentId={moduloB.documentId}
          participant={moduloB.participant}
          onClose={() => {
            refresh();
            setModuloB(null);
          }}
        />
      )}
    </Container>
  );
};

export default Documents;
