import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  PRACTICE_STATUS_OPZIONATO,
  PRACTICE_STATUS_CONFERMATO,
  PRACTICE_STATUS_IN_LAVORAZIONE,
  PRACTICE_STATUS_PREVENTIVATO,
  PRACTICE_STATUS_ANNULLATO,
  PRACTICE_STATUS_CONCLUSO,
  PRACTICE_STATUS_STAND_BY,
  PRACTICE_TYPE_PRIVATO,
  PRACTICE_TYPE_INPS,
  PRACTICE_TYPE_ALTRO,
  PRACTICE_TYPE_AZIENDA_CONVENZIONATA,
  PRACTICE_TYPE_WELFARE,
  PRACTICE_TYPE_INPSNUOVO,
} from '../utils/constants';

//remapping because we receive number values from our api
export const PRACTICE_TYPES = {
  1: PRACTICE_TYPE_PRIVATO,
  2: PRACTICE_TYPE_INPS,
  3: PRACTICE_TYPE_ALTRO,
  4: PRACTICE_TYPE_AZIENDA_CONVENZIONATA,
  5: PRACTICE_TYPE_WELFARE,
  6: PRACTICE_TYPE_INPSNUOVO,
};

const statusColorMap = {
  [PRACTICE_STATUS_OPZIONATO]: '#3c8dbc',
  [PRACTICE_STATUS_CONFERMATO]: '#00a65a',
  [PRACTICE_STATUS_IN_LAVORAZIONE]: '#3c8dbc',
  [PRACTICE_STATUS_PREVENTIVATO]: '#d2d6de',
  [PRACTICE_STATUS_ANNULLATO]: '#dd4b39',
  [PRACTICE_STATUS_CONCLUSO]: '#dd4b39',
  [PRACTICE_STATUS_STAND_BY]: '#d2d6de',
};

const mapPracticeType = type => PRACTICE_TYPES[type] || '';

const mapStatusColor = status => statusColorMap[status] || '#d2d6de'; // default value grey (preventivato/stand-by)

const PracticeItem = ({practice, children}) => (
  <Container>
    <Panel>
      <Summary>
        <Grid container>
          <GridItem item xs={12} md={2}>
            <b>{practice.shortCode}</b>
          </GridItem>

          <GridItem item xs={12} md={4}>
            <BadgeBox bgcolor="#1f6199" color="white">
              {practice.practiceDivision}
            </BadgeBox>

            <BadgeBox
              bgcolor={mapStatusColor(practice.readableStatus)}
              color="white"
            >
              {practice.readableStatus.toUpperCase()}
            </BadgeBox>

            {mapPracticeType(practice.practiceType).toUpperCase()}
          </GridItem>

          <GridItem item xs={12} md={4}>
            {practice.packageTitle}
          </GridItem>

          <GridItem item xs={12} md={2}>
            {practice.destination}
          </GridItem>
        </Grid>
      </Summary>

      <Details>{children}</Details>
    </Panel>
  </Container>
);

export default PracticeItem;

PracticeItem.propTypes = {
  children: PropTypes.node,
  practice: PropTypes.shape({
    code: PropTypes.string,
    practiceDivision: PropTypes.string,
    readableStatus: PropTypes.string,
    practiceType: PropTypes.number,
    packageTitle: PropTypes.string,
    destination: PropTypes.string,
  }),
};

const Container = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const Panel = styled(ExpansionPanel).attrs({elevation: 0})`
  width: 100%;
`;

const expansionStyle = css`
  border-radius: 4px;
  border: 1px solid lightgray;
  //min-width: 900px; edit_20231020
`;

const Summary = styled(ExpansionPanelSummary).attrs({
  expandIcon: <ExpandMoreIcon />,
})`
  ${expansionStyle};
`;

const Details = styled(ExpansionPanelDetails)`
  ${expansionStyle};
  display: block;
  margin-top: 16px;
`;

const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  overflow: hidden;
  padding: 4px 16px;
  border-right: 1px solid lightgrey;
`;

const BadgeBox = styled(Box).attrs({
  fontWeight: 500,
})`
  margin: 4px 16px 4px 0;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
`;
