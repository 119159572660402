import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {Input, Button, CircularProgress} from '@material-ui/core';
import {Edit, Save, Check, Close} from '@material-ui/icons';

//button states
const EDITING = 'editing';
const SUBMITTING = 'submitting';
const SUCCESS = 'success';
const ERROR = 'error';
const DEFAULT = 'default';

class ToggleEditField extends Component {
  inputRef = React.createRef();

  state = {
    text: this.props.value,
    inputState: DEFAULT,
    avoidExternalFocus: this.props.avoidExternalFocus ?? false, //Variabile che, se settata, evita che il click all'esterno dell'input triggeri il salvataggio
  };

  componentDidUpdate() {
    if (this.state.inputState === EDITING) {
      this.inputRef.current.focus();
    }
  }

  onUpdate = async () => {
    if (this.props.value === this.state.text) {
      this.setState({inputState: DEFAULT});
      return;
    }

    this.setState({inputState: SUBMITTING});

    try {
      await this.props.updateValue(this.state.text);

      this.setState({inputState: SUCCESS});

      setTimeout(() => {
        this.setState({inputState: DEFAULT});
      }, 3000);
    } catch {
      this.setState({inputState: ERROR, text: this.props.value});

      setTimeout(() => {
        this.setState({inputState: DEFAULT});
      }, 3000);
    }
  };

  getButtonPropsFromState = () => {
    const iconProps = {
      fontSize: 'small',
      color: 'secondary',
    };

    const {inputState} = this.state;

    if (inputState === ERROR) {
      return {
        icon: <Close {...iconProps} />,
        style: {background: 'red'},
      };
    }

    if (inputState === SUCCESS) {
      return {
        icon: <Check {...iconProps} />,

        style: {background: 'lightgreen'},
      };
    }

    if (inputState === SUBMITTING) {
      return {icon: <Spinner />, disabled: true, style: {background: 'green'}};
    }

    if (inputState === EDITING) {
      let saveIcon = {
        icon: <Save {...iconProps} />,
        style: {background: 'green'},
      };
      if(this.state.avoidExternalFocus){
        //L'icona deve funzionare come pulsante e solo al click del pulsante effettua la chiamate
        saveIcon.onClick = () => this.onUpdate();
      }
      return saveIcon;
    }

    return {
      icon: <Edit {...iconProps} />,
      onClick: () => this.setState({inputState: EDITING}),
    };
  };

  render() {
    const {icon, ...buttonProps} = this.getButtonPropsFromState();
    const isEditing = this.state.inputState === EDITING;
    return (
      <Row>
        <IconButton {...buttonProps}>{icon}</IconButton>
        <Input
          value={this.state.text}
          onChange={evt => this.setState({text: evt.target.value})}
          inputProps={{ref: this.inputRef}}
          readOnly={!isEditing}
          disableUnderline={!isEditing}
          onBlur={() => !this.state.avoidExternalFocus && isEditing && this.onUpdate()}
          style={{fontSize: 14}}
          fullWidth
          multiline
        />
      </Row>
    );
  }
}

export default ToggleEditField;

ToggleEditField.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func.isRequired,
};

const Row = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const IconButton = styled(Button).attrs(props => {
  const {palette} = props.theme;

  let background = palette.primary.main;
  let disabled = false;

  if (props.status === SUCCESS) {
    background = palette.success.light;
    disabled = true;
  }

  if (props.status === SUBMITTING) {
    background = palette.success.main;
    disabled = true;
  }

  if (props.status === EDITING) {
    background = palette.success.main;
  }

  return {
    disabled,
    style: {background},
    variant: 'contained',
    disableElevation: true,
  };
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  min-width: 20px;
  padding: 0;
  margin: 0;
  margin-right: 8px;

  &:hover {
    ${props =>
      props.status && `background-color: ${props.theme.palette.success.light}`};
  }
`;

const Spinner = styled(CircularProgress).attrs({
  color: 'secondary',
  size: 15,
})``;
