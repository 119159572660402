import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Checkbox,
  FormControlLabel,
  Typography,
  Dialog,
} from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import Button from '../Button';
import {
  PRACTICE_DIVISION_GIOCAMONDO_STUDY,
  PRACTICE_DIVISION_LA_MIA_ESTATE,
} from '../../utils/constants';

class PaymentBankTransfer extends Component {
  state = {selected: false, expanded: false, showModal: false};

  toggleExpanded = () => this.setState({expanded: !this.state.expanded});

  toggleModal = () => this.setState({showModal: !this.state.showModal});

  onSelect = () => {
    this.props.toggleBankTransferSelected();

    if (this.state.expanded) {
      this.setState({selected: !this.state.selected});
      return;
    }

    this.setState({selected: !this.state.selected, expanded: true});
  };

  render() {
    const {selected, expanded, showModal} = this.state;
    const bankDetails = bankDetailsMap(this.props.shortCode);

    return (
      <Container>
        <ExpansionPanel expanded={expanded}>
          <ExpansionPanelSummary
            expandIcon={<ExpandIcon />}
            onClick={this.toggleExpanded}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={selected}
                  onChange={this.onSelect}
                />
              }
              label={PANEL_LABEL}
              onClick={event => event.stopPropagation()}
            />
          </ExpansionPanelSummary>

          <TextBlock>
            {PANEL_DETAILS}
            <br />
            <br />
            {bankDetails[this.props.practiceDivision]}
          </TextBlock>

          <PayButton
            text="Paga con bonifico"
            disabled={!selected}
            onClick={this.toggleModal}
          />
        </ExpansionPanel>

        <Dialog open={showModal} onClose={this.toggleModal}>
          <TextBlock>
            {DIALOG_TEXT}

            {/*<ConfirmButton text="Ok, ho capito" onClick={this.toggleModal} />*/}
            <ConfirmButton text="Chiudi" onClick={this.toggleModal} />
          </TextBlock>
        </Dialog>
      </Container>
    );
  }
}

const PANEL_LABEL =
  'Bonifico ordinario da effettuare (riceverai una mail con tutti gli estremi e le indicazioni per procedere al pagamento)';

const PANEL_DETAILS = (
  <>
    <b>
    Effettuare il pagamento entro 48 ore dalla presente prenotazione.
    </b>{' '}
    {/*Inviare copia del bonifico via mail a{' '}
    <a href="mailto:pagamenti@giocamondo.it">pagamenti@giocamondo.it</a> o via
    fax al 0736 670125 indicando nell’oggetto il nome del partecipante.*/}
  </>
);

const bankDetailsMap = (shortCode) => ({
  [PRACTICE_DIVISION_GIOCAMONDO_STUDY]: (
    <>
      <p>Dettagli del bonifico bancario:</p>
      <b>IBAN: IT03M0538713504000042005710 (BPER Banca)</b>
      <p>CONTO INTESTATO A: Giocamondo Study srl</p>
      <p>BIC: BPMOIT22XXX</p>
      <p>N.B. Nella CAUSALE questo numero della prenotazione Giocamondo: <strong>{shortCode}</strong>.</p> 
      <p>La causale deve essere scritta così: <strong className='shortCode'>Pratica {shortCode} Nome Cognome del partecipante</strong>.</p>
      {/*<hr/>
      <p>NB. per i clienti privati si ricorda che gli acconti da versare corrispondono a:</p>
      <p>- € 600 per destinazioni in Europa<br/>
      - € 900 per destinazioni Extra Europa</p>*/}
    </>
  ),
    [PRACTICE_DIVISION_LA_MIA_ESTATE]: (
    <>
      <p>Solo per <strong>clienti INPS</strong> - Dettagli del bonifico bancario:</p>
      <b>IBAN: IT02F0306913507100000005144 (Banca Intesa San Paolo)</b>
      <p>CONTO INTESTATO A: Giocamondo soc. coop.</p>
      <p>BIC: BCITITMM</p>
      <p>N.B. Nella CAUSALE questo numero della prenotazione Giocamondo: <strong>{shortCode}</strong>.</p> 
      <p>La causale deve essere scritta così: <strong className='shortCode'>Pratica {shortCode} Nome Cognome del partecipante</strong>.</p>
      <hr/>
      <p>Solo per <strong>clienti Privati</strong> - Dettagli del bonifico bancario:</p>
      <b>IBAN: Unicredit iban IT70D0200813507000102592693</b>
      <p>BIC: UNCRITM1E07</p>
      <p>CONTO INTESTATO A: Giocamondo soc. coop.</p>
      <p>La causale deve essere scritta così: <strong className='shortCode'>Pratica {shortCode} Nome Cognome del partecipante</strong>.</p>

    </>
  ),
});

const DIALOG_TEXT = (
  <>
    <h3>
      <b>Grazie per aver saldato la tua prenotazione!</b>
    </h3>
    {/*<p>
      Invia una copia del bonifico via mail a{' '}
      <a href="mailto:pagamenti@giocamondo.it"> pagamenti@giocamondo.it</a> o
      via fax al 0736 670125 indicando nell'oggetto il codice della prenotazione e
      nome del partecipante.
    </p>*/}
  </>
);

PaymentBankTransfer.propTypes = {
  practiceDivision: PropTypes.string.isRequired,
  toggleBankTransferSelected: PropTypes.func.isRequired,
  shortCode: PropTypes.string.isRequired, // Add shortCode as a required prop
};

export default PaymentBankTransfer;

const Container = styled.div`
  margin-top: 16px;
`;

const PayButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
})`
  display: block;
  margin: 32px auto 16px;
  color: white;
`;

const TextBlock = styled(Typography).attrs({
  component: 'div',
  variant: 'body1',
})`
  padding: 16px;
  & > p,
  b {
    margin: 0;
  }
  & span {
    font-weight: 600;
  }
`;

const ConfirmButton = styled(Button).attrs({
  variant: 'contained',
})`
  margin: 16px auto;
`;
