import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
import {Fade} from '@material-ui/core';

const StatusAlert = ({visible, severity, children, centered}) => {
  const [statusAlert, setStatusAlert] = useState({severity, children});

  useEffect(() => {
    //save alert data to state so it can persist for the fade out animation
    if (visible) {
      setStatusAlert({severity, children});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Fade in={visible} timeout={1000}>
      <StyledAlert severity={statusAlert.severity} centered={centered}>
        {statusAlert.children}
      </StyledAlert>
    </Fade>
  );
};

StatusAlert.propTypes = {
  visible: PropTypes.bool,
  severity: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default StatusAlert;

const StyledAlert = styled(MuiAlert)`
  position: fixed;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: ${props => (props.centered ? "50%" : "100%")};
  top: ${props => (props.centered ? "50%" : "0")};
  left: ${props => (props.centered ? "25%" : "0")};
  z-index: 999;
`;
