import React, {useCallback, useState} from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    Grid,
    Radio,
    TextField,
    Typography
} from '@material-ui/core';
import {format as formatDate, isValid as isValidDate} from 'date-fns';
import ToggleEditField from './Form/ToggleEditField';
import {updatePracticeDetails} from '../utils/api';
// import {RadioButtons} from './Form'
import ToggleEditGroup from './Form/ToggleEditGroup';
import throttle from 'lodash/throttle';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const dataToSubmit = values => ({
    grade: values.grade || '',
    intolerancesText: values.intolerancesText || '',
    note: values.note || '',
    noteDocument: values.noteDocument || '',
    noteEducation: values.noteEducation || '',
    noteLeaving: values.noteLeaving || '',
    noteRooming: values.noteRooming || '',
    docType: values.docType || '',
    docNumber: values.docNumber || '',
    docEmission: values.docEmission || '',
    docCountry: values.docCountry || '',
    docExpiration: values.docExpiration || '',
    fixedPracticeInpsNumber: values.fixedPracticeInpsNumber || '',
    variablePracticeInpsNumber: values.variablePracticeInpsNumber || ''
});

const documentToSubmit = values => ({
    docType: values.docType || '',
    docNumber: values.docNumber || '',
    docEmission: values.docEmission || '',
    docCountry: values.docCountry || '',
    docExpiration: values.docExpiration || ''
})

const ParticipantDetails = ({practiceId, editable, ...values}) => {
    const [updatedValues, setUpdatedValues] = useState(dataToSubmit(values));
    const [documentValues, setDocumentValues] = useState(documentToSubmit(values))
    const [documentEditable, setDocumentEditable] = useState(false)
    const editableAsBinary = editable ? 1 : 0;

    const onValueUpdate = useCallback(
        async (name, value) => {
            let newValues = {...updatedValues, [name]: value};
            newValues.id = values.id;

            await updatePracticeDetails(practiceId, newValues);
            setUpdatedValues(newValues);
        },

        [practiceId, updatedValues]
    );

    const onDocumentUpdate = useCallback(
        async () => {
            if (!documentValidate()) {
                setDocumentEditable(true);
                throw new Error()
            }
            const newValues = {
                id: values.id,
                ...updatedValues,
                ...documentValues,
            };

            try {
                const resp = await updatePracticeDetails(practiceId, newValues);
                if (resp) {
                    setUpdatedValues(newValues);
                }
            } catch (e) {
                console.error(e);
            }
        },

        [practiceId, updatedValues, documentValues]
    );

    const documentShouldUpdate = useCallback(
        () => {
            return documentValues.docType !== updatedValues.docType ||
                documentValues.docNumber !== updatedValues.docNumber ||
                documentValues.docEmission !== updatedValues.docEmission ||
                documentValues.docExpiration !== updatedValues.docExpiration
        },
        [updatedValues, documentValues]
    )

    const documentValidate = useCallback(
        () => {
            if (!documentShouldUpdate) {
                return true
            }
            if (!documentValues.docType) {
                alert('Selezionare almeno un tipo di documento')
                return false
            }
            if (!documentValues.docNumber) {
                alert('Indicare il numero di documento')
                return false
            }
            if (!documentValues.docEmission || !documentValues.docExpiration || !isValidDate(new Date(documentValues.docEmission)) || !isValidDate(new Date(documentValues.docExpiration))) {
                alert('Le date di emissione e scadenza del documento devono essere due date valide')
                return false
            }
            if (documentValues.docType === 'passport') {
                const res = /^[a-zA-Z]{2}\d{7}$/.test(documentValues.docNumber)
                if (!res) {
                    alert('Numero Passaporto, deve contenere due lettere e sette numeri.')
                    return false
                }
            }
            if (documentValues.docType === 'idCard') {
                const res = /^(?:[a-zA-Z]{2}\d{7})|(?:[a-zA-Z]{2}\d{5}[a-zA-Z]{2})$/.test(documentValues.docNumber)
                if (!res) {
                    alert('Numero Carta Identità, deve contenere due lettere e sette numeri, oppure due lettere, cinque numeri e due lettere.')
                    return false
                }
            }
            if ((new Date(documentValues.docExpiration)).getTime() <= (new Date(documentValues.docEmission)).getTime()) {
                alert('La data di scadenza del documento deve essere posteriore alla data di emissione')
                return false
            }

            return true
        },
        [documentValues, documentShouldUpdate]
    )

    const renderField = useCallback(
        name => {
            if (!editable) {
                return values[name];
            }

            return (
                <ToggleEditField
                    value={updatedValues[name]}
                    updateValue={text => onValueUpdate(name, text)}
                />
            );
        },
        [editable, onValueUpdate, updatedValues, values]
    );


    const renderPracticeInpsNumberField = useCallback(
        name => {
            if (!editable) {
                return values[name];
            }

            return (
                <GridContainer>
                    {values.fixedPracticeInpsNumber}
                    <ToggleEditField
                        value={updatedValues[name]}
                        updateValue={text => onValueUpdate(name, text)}
                    />
                </GridContainer>
            );
        },
        [editable, onValueUpdate, updatedValues, values]
    );

    const renderDocumentNumber = useCallback(
        (name) => {
            if (!editable || !documentEditable) {
                return documentValues[name]
            }
            const descriptionIdCard = 'Numero Carta Identità, deve contenere due lettere e sette numeri, oppure due lettere, cinque numeri e due lettere.'
            const descriptionPassport = 'Numero Passaporto, deve contenere due lettere e sette numeri.'
            const description = documentValues.docType === 'idCard' ? descriptionIdCard : documentValues.docType === 'passport' ? descriptionPassport : `${descriptionIdCard} ${descriptionPassport}`
            const helperText = description
            return <TextField
                value={documentValues[name]}
                onChange={throttle(evt => {
                    evt.persist();
                    setDocumentValues(state => ({...state, [name]: evt.target.value}))
                }, 200)}
                // inputProps={{ref: this.inputRef}}
                readOnly={!documentEditable}
                // disableUnderline={!documentEditable}
                // onBlur={() => isEditing && this.onUpdate()}
                style={{fontSize: 14}}
                helperText={helperText}
                fullWidth
                multiline
            />
        }
    )

    const renderDocumentDate = useCallback(
        (name) => {
            if (!editable || !documentEditable) {
                const value = documentValues[name]
                // console.log({value})
                const date = new Date(value)
                return isValidDate(date) ? formatDate(date, 'dd/MM/yyyy') : value
            }
            return <DateField
                value={documentValues[name]}
                // type='date'
                onChange={evt => {
                    evt.persist();
                    setDocumentValues(state => ({...state, [name]: evt.target.value}));
                }}
                // inputProps={{ref: this.inputRef}}
                readOnly={!documentEditable}
                // disableUnderline={!documentEditable}
                // onBlur={() => isEditing && this.onUpdate()}
                style={{fontSize: 14}}
                // fullWidth
                // multiline
            />
        }
    )

    const renderDocumentRadioGroup = useCallback(
        (name, data = [], description) => {
            // const inputProps = useField(props.name, {
            //   ...props,
            // });

            // const error = !!(inputProps.meta.error || inputProps.meta.submitError);
            // const helperText =
            //   inputProps.meta.error || inputProps.meta.submitError || props.helperText;
            const helperText = description
            const disabled = !editable || !documentEditable

            return (
                <>
                    {data.map(item => (
                        <FormControlLabel
                            key={`${name}_${item.value}`}
                            control={
                                <Radio
                                    name={name}
                                    disabled={disabled}
                                    color="primary"
                                    checked={item.value === documentValues.docType}
                                    value={item.value}
                                    onChange={evt => evt.target.checked && setDocumentValues(state => ({
                                        ...state,
                                        [name]: item.value
                                    }))}
                                />
                            }
                            label={item.label}
                        />
                    ))}

                    {/* {error && <ErrorMessage>{helperText}</ErrorMessage>} */}
                </>
            );
        }
    )

    const renderDocumentGroupTitle = useCallback(
        () => {
            if (!editable) {
                return <DocumentTitle>DOCUMENTO</DocumentTitle>;
            }

            return (
                <ToggleEditGroup
                    onUpdate={() => onDocumentUpdate()}
                    setChildrenEditable={value => setDocumentEditable(value)}
                    shouldUpdate={() => documentShouldUpdate()}
                >
                    <DocumentTitle>DOCUMENTO</DocumentTitle>
                </ToggleEditGroup>
            );
        },
        [editable, onDocumentUpdate, setDocumentEditable]
    );

    return (
        <Container>
            <Panel>
                <Summary>Partecipante:&nbsp;<strong>{values.customerName}</strong></Summary>
                <Details className="responsivecolumn">
                    <NotesContainer>
                        <GridContainer>
                            <Title>PARTECIPANTE</Title>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Nome:</GridLabel>
                            <GridItem editable={editableAsBinary}>{values.customerName}</GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Codice Fiscale:</GridLabel>
                            <GridItem editable={editableAsBinary}>
                                {values.customerFiscalCode}
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Relazione:</GridLabel>
                            <GridItem editable={editableAsBinary}>{values.relation}</GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Media scolastica:</GridLabel>
                            <GridItem>{renderField('grade')}</GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Disabilità:</GridLabel>
                            <GridItem editable={editableAsBinary}>
                                {values.disabilita ? 'Sì' : 'No'}
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Assistenza:</GridLabel>
                            <GridItem editable={editableAsBinary}>
                                {values.assistenza ? 'Sì' : 'No'}
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Certificazioni mediche:</GridLabel>
                            <GridItem editable={editableAsBinary}>
                                {values.medicalCertification ? 'Sì' : 'No'}
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Intolleranze:</GridLabel>
                            <GridItem editable={editableAsBinary}>
                                {values.intolerances ? 'Sì' : 'No'}
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridLabel>Elenco intolleranze:</GridLabel>
                            <GridItem>{renderField('intolerancesText')}</GridItem>
                        </GridContainer>
                    </NotesContainer>

                    <AdditionalNotesContainer>
                        <GridContainer>
                            <Title>NOTE</Title>
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Note:</NotesGridLabel>
                            <NotesGridItem>{renderField('note')}</NotesGridItem>
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Documenti:</NotesGridLabel>
                            <NotesGridItem>{renderField('noteDocument')}</NotesGridItem>
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Educazione:</NotesGridLabel>
                            <NotesGridItem>{renderField('noteEducation')}</NotesGridItem>
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Leaving:</NotesGridLabel>
                            <NotesGridItem>{renderField('noteLeaving')}</NotesGridItem>
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Rooming:</NotesGridLabel>
                            <NotesGridItem>{renderField('noteRooming')}</NotesGridItem>
                        </GridContainer>

                        <GridContainer>
                            {renderDocumentGroupTitle()}
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Tipo Documento:</NotesGridLabel>
                            <NotesGridItem>{renderDocumentRadioGroup('docType', [{
                                label: 'Carta d\'identità',
                                value: 'idCard'
                            }, {label: 'Passaporto', value: 'passport'}])}</NotesGridItem>
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Numero Documento:</NotesGridLabel>
                            <NotesGridItem>{renderDocumentNumber('docNumber')}</NotesGridItem>
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Data emissione:</NotesGridLabel>
                            <NotesGridItem>{renderDocumentDate('docEmission')}</NotesGridItem>
                        </GridContainer>

                        <GridContainer>
                            <NotesGridLabel>Data scadenza:</NotesGridLabel>
                            <NotesGridItem>{renderDocumentDate('docExpiration')}</NotesGridItem>
                        </GridContainer>
                        <GridContainer>
                            <NotesGridLabel>Nazionalità sul documento:</NotesGridLabel>
                            <NotesGridItem>{renderField('docCountry')}</NotesGridItem>
                        </GridContainer>
                        <GridContainer>
                            <NotesGridLabel>Numero pratica INPS:</NotesGridLabel>
                            <NotesGridItem>{renderPracticeInpsNumberField('variablePracticeInpsNumber')}</NotesGridItem>
                        </GridContainer>
                    </AdditionalNotesContainer>
                </Details>
            </Panel>
        </Container>
    );
};

ParticipantDetails.propTypes = {
    customerName: PropTypes.string,
    customerFiscalCode: PropTypes.string,
    relation: PropTypes.string,
    grade: PropTypes.string,
    disabilita: PropTypes.bool,
    assistenza: PropTypes.bool,
    intolerances: PropTypes.bool,
    intolerancesText: PropTypes.string,
    note: PropTypes.string,
    noteDocument: PropTypes.string,
    noteEducation: PropTypes.string,
    noteLeaving: PropTypes.string,
    noteRooming: PropTypes.string,
    practiceId: PropTypes.number,
    editable: PropTypes.bool,
};

export default ParticipantDetails;

const Container = styled(Grid).attrs({container: true})`
  margin-top: 16px;
  background-color: #f7f7f7;
  border-radius: 4px;
  //padding: 32px;
`;
const Panel = styled(ExpansionPanel).attrs({elevation: 0})`
  width: 100%;
`;

const expansionStyle = css`
  border-radius: 4px;
  border: 1px solid lightgray;
  font-size: 1.05rem;
  background-color: #ecebeb;
  //min-width: 900px;
`;

const Summary = styled(ExpansionPanelSummary).attrs({
    expandIcon: <ExpandMoreIcon/>,
})`
  ${expansionStyle};
  //TODO aggiungere qui css
`;


const Details = styled(ExpansionPanelDetails)`
  ${expansionStyle};
  //display: block;
  margin-top: 16px;
`;

const Title = styled(Typography).attrs({
    component: 'h2',
    variant: 'h6',
})`
  display: block;
  margin-bottom: 24px;
`;

const DocumentTitle = styled(Typography).attrs({
    component: 'h2',
    variant: 'h6',
})`
  display: block;
  margin-bottom: 24px;
  margin-top: 32px;
`;

const NotesContainer = styled(Grid).attrs({
    item: true,
    sm: 4,
    xs: 12,
})`
  padding-right: 16px;
`;

const AdditionalNotesContainer = styled(Grid).attrs({
    item: true,
    sm: 8,
    xs: 12,
})`
  padding-left: 16px;
  border-left: 2px solid white;
`;

const GridContainer = styled(Grid).attrs({
    container: true,
    alignItems: 'center',
})`
  min-height: 28px;
`;

const GridItem = styled(Grid).attrs({item: true, sm: 6, xs: 12})`
  ${props => props.editable && 'padding-left: 29px;'};
`;

const GridLabel = styled(GridItem)`
  font-weight: 700;
`;

const NotesGridItem = styled(Grid).attrs({item: true, sm: 9, xs: 12})``;

const NotesGridLabel = styled(Grid).attrs({item: true, sm: 3, xs: 12})`
  font-weight: 700;
`;

const DateField = styled(TextField).attrs({
    fullWidth: true,
    type: 'date',
    InputLabelProps: {
        shrink: true,
    },
})``;
