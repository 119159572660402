import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {Link, Typography, Container} from '@material-ui/core';

import Logo from '../components/LoginSectionLogo';
import {TextField, FormError} from '../components/Form';
import {SecondarySubmitButton} from '../utils/commonStyledComponents';
import {passwordRecovery} from '../utils/api';

const ForgotPassword = () => {
  const onSubmit = useCallback(async data => {
    try {
      await passwordRecovery(data);
    } catch (e) {
      return {
        [FORM_ERROR]: e.response?.data?.message,
      };
    }
  }, []);

  return (
    <div>
      <Logo />
      <StyledContainer>
        <FormTitle>Recupero Password</FormTitle>

        <Form
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            submitting,
            submitError,
            submitSucceeded,
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              {!submitting && submitError && (
                <FormError>{submitError}</FormError>
              )}

              {submitSucceeded && (
                <Subtitle>
                  La tua password è stata modificata e una copia ti è stata
                  inviata alla email associata al tuo account.
                </Subtitle>
              )}

              <TextField
                name="username"
                label="username o indirizzo e-mail"
                fullWidth
                required
              />

              <SecondarySubmitButton
                text="Invia"
                disabled={submitting}
                loading={submitting}
              />

              <BackLink href="/login" variant="body2">
                Torna alla login
              </BackLink>
            </StyledForm>
          )}
        />
      </StyledContainer>
    </div>
  );
};

export default ForgotPassword;

const StyledContainer = styled(Container).attrs({
  component: 'main',
  maxWidth: 'xs',
})`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const FormTitle = styled(Typography).attrs({
  variant: 'h2',
  align: 'center',
  color: 'textSecondary',
})`
  margin-top: 32px;
`;

const Subtitle = styled(Typography).attrs({
  component: 'p',
  variant: 'body1',
  align: 'center',
})`
  margin: 32px 0;
  background: rgb(255, 244, 229);
  border: solid 1px rgb(255, 209, 42);
  color: rgb(102, 60, 0);
  padding: 3% 2%;
  border-radius: 3px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const BackLink = styled(Link)`
  align-self: center;
`;
