import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import Container from '@material-ui/core/Container';

import {useUser} from '../utils/UserSession';
//import {bootIntercom} from '../utils/intercom';

export default function Logout() {
  const {setUser, setToken} = useUser();
  const history = useHistory();

  useEffect(() => {
    setToken('');
    setUser({});

    //window.Intercom('shutdown');
    //bootIntercom();

    history.replace('/');
  });

  return (
    <Container component="main" maxWidth="xs">
      <h3>Logging out...</h3>
    </Container>
  );
}
