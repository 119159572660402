import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {useHistory} from 'react-router-dom';
import {
  Typography,
  Link,
  MenuItem,
  Grid,
  Container,
  TextField as MaterialTextField,
} from '@material-ui/core';

import Logo from '../components/LoginSectionLogo';
import Button from '../components/Button';
import FormErrorsPopup from '../components/Form/FormErrorsPopup';
import {
  TextField,
  FormError,
  CheckboxField,
  PasswordField,
  RadioButtons,
  AutocompleteField,
} from '../components/Form';
import {
  registerMember,
  fetchCities,
  calculateFiscalCode,
  login,
  fetchUser,
} from '../utils/api';
import {useUser} from '../utils/UserSession';
import {
  validateRegister,
  validateFiscalCode,
} from '../components/Form/validation';
import {mutators} from '../components/Form/utils';
import {SecondarySubmitButton} from '../utils/commonStyledComponents';
import StatusAlert from '../components/StatusAlert';
import {gtagRegister} from '../utils/gtag';

export default function Register() {
  const {setUser, setToken} = useUser();
  const history = useHistory();

  const [cities, setCities] = useState([]);
  const [loadingFiscalCode, setLoadingFiscalCode] = useState(false);
  const [fiscalCodeErrors, setFiscalCodeErrors] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchCities();
        setCities(data);
      } catch (e) {
        return {
          [FORM_ERROR]:
            e.response?.data?.message ||
            'Si è verificato un errore nella comunicazione con il server',
        };
      }
    })();
  }, []);

  const onSubmit = useCallback(
    async data => {
      try {
        const errors = validateRegister(data);

        if (errors) {
          return errors;
        }

        await registerMember(data);

        //register user via google analytics
        gtagRegister();

        setTimeout(async () => {
          const tokenResponse = await login({
            username: data.email1,
            password: data.password,
          });
          setToken(tokenResponse.token);

          const user = await fetchUser();
          setUser(user);

          history.push('/');
        }, 1000);
      } catch (e) {
        const {data} = e.response || {};

        if (data.errors) {
          return data.errors;
        }

        return {
          [FORM_ERROR]:
            data.message ||
            'Si è verificato un errore nella comunicazione con il server',
        };
      }
    },
    [history, setToken, setUser]
  );

  const setFiscalCode = useCallback(async (form, values) => {
    try {
      setLoadingFiscalCode(true);

      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        birthDate: values.birthDate,
        birthPlace: values.birthPlace,
      };

      const errors = validateFiscalCode(data);

      if (errors) {
        setFiscalCodeErrors(Object.values(errors));
        setLoadingFiscalCode(false);
        return;
      }

      const fiscalCode = await calculateFiscalCode(data);
      form.mutators.setFiscalCode(fiscalCode);
      setLoadingFiscalCode(false);
    } catch (e) {
      const responseErrors = e.response?.data?.errors || [];
      const errors = [];

      for (let error in responseErrors) {
        errors.push(responseErrors[error][0]);
      }

      setFiscalCodeErrors(errors);
      setLoadingFiscalCode(false);
    }
  }, []);

  return (
    <>
      <Logo />
      <RegisterContainer>
        <FormErrorsPopup
          title="Dati necessari per il calcolo del codice:"
          errors={fiscalCodeErrors}
          close={() => setFiscalCodeErrors([])}
        />

      <Typography variant="subtitle1" gutterBottom component="div" className='text-bordered'>
        Attenzione: per i partecipanti al bando Estate INPSieme è fondamentale che la registrazione all'area riservata "Giocamondo Club" venga effettuata con i dati (in particolare Codice Fiscale ed E-mail) del genitore titolare INPS che ha effettuato o effettuerà una prenotazione con Giocamondo.
      </Typography>

        <Form
          onSubmit={onSubmit}
          mutators={mutators}
          initialValues={{
            nationality: 'Italiana',
          }}
          render={({
            values,
            form,
            handleSubmit,
            submitting,
            submitSucceeded,
            submitError,
          }) => (
            <form onSubmit={handleSubmit} noValidate={true}>
              <FormTitle>Registrati</FormTitle>

              {!submitting && submitError && (
                <FormError>{submitError}</FormError>
              )}

              <StatusAlert visible={submitSucceeded}>
                Registrazione effettuata con successo. Ti stiamo reindirizzando
                alla tua area privata.
              </StatusAlert>

              <GridRow>
                <Grid item sm xs={12}>
                  <Field name="firstName" label="Nome" />
                </Grid>

                <Grid item sm xs={12}>
                  <Field name="lastName" label="Cognome" />
                </Grid>

                <Grid item sm="auto" xs={12}>
                  <RadioButtons
                    name="gender"
                    options={[
                      {value: 'F', label: 'Donna'},
                      {value: 'Z', label: 'Uomo'},
                    ]}
                  />
                </Grid>
              </GridRow>

              <GridRow>
                <Grid item sm={4} xs={12}>
                  <Field select name="nationality" label="Nazione di nascita">
                    <MenuItem value="Italiana">Italiana</MenuItem>
                    <MenuItem value="Estera">Estera</MenuItem>
                  </Field>
                </Grid>

                {values.nationality === 'Italiana' ? (
                  <>
                    <Grid item sm={6} xs={10}>
                      <AutocompleteField
                        options={cities.map(city => city.name)}
                        getOptionLabel={city => city}
                        name="birthPlace"
                        label="Nato a"
                        loading={!cities.length}
                      />
                    </Grid>

                    <Grid item sm={2} xs={2}>
                      <MaterialTextField
                        value={
                          cities.find(({name}) => name === values.birthPlace)
                            ?.car || ''
                        }
                        label="Prov"
                        disabled
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item sm={4} xs={12}>
                      <Field name="birthNation" label="Nazione" />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Field name="foreignBirthCity" label="Città di nascita" />
                    </Grid>
                  </>
                )}
              </GridRow>

              <GridRow>
                <Grid item sm={4} xs={12}>
                  <DateField name="birthDate" label="Data di nascita" />
                </Grid>

                <Grid item sm="auto" xs={5}>
                  <FiscalCodeButton
                    text="Calcola CF"
                    loading={loadingFiscalCode}
                    onClick={() => setFiscalCode(form, values)}
                    disabled={
                      values.nationality !== 'Italiana' || loadingFiscalCode
                    }
                  />
                </Grid>

                <Grid item sm xs>
                  <Field name="fiscalCode" label="Codice Fiscale" />
                </Grid>
              </GridRow>

              <GridRow>
                <Grid item sm={6} xs={12}>
                  <Field name="email1" label="Email" />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Field name="mobilePhone" label="Cellulare" />
                </Grid>
              </GridRow>

              <GridRow>
                <Grid item sm={6} xs={12}>
                  <Password name="password" label="Password" />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Password name="repeatPassword" label="Ripeti Password" />
                </Grid>
              </GridRow>


              {/*<Typography variant="subtitle1" gutterBottom component="div" className='text-bordered'>*/}
              {/*  Sei un docente certificato da Giocamondo e fai parte del piano viaggi Gruppo Docenti? Spunta la casella qui sotto per dichiararlo*/}
              {/*  <br/>*/}
              {/*  <CheckboxField*/}
              {/*      name="memberIsDocente"*/}
              {/*      label="Sì, sono un docente che fa parte del piano dei viaggi Giocamondo per Gruppo Docenti."*/}
              {/*  />*/}
              {/*</Typography>*/}

              <Typography component="div" variant="caption">
                <p>
                  Informativa estesa sulla{' '}
                  <a href="https://www.iubenda.com/privacy-policy/883329">
                    privacy
                  </a>
                  . Ai sensi e per gli effetti dell'art.4 DPR 445/2000, in relazione al rilascio di dichiarazioni mendaci, dichiaro che le informazioni rese nel presente form corrispondono a verità e accetto che queste vengano utilizzate unicamente per le finalità per le quali sono state acquisite (GDPR - Regolamento 2016/679). Per quanto riguarda i consensi da lei sottoscritti le comunichiamo che limiteremo le nostre attività sui vostri dati, per le finalità indicate nell’informativa, entro i limiti delle autorizzazioni che ci avete concesso e al fine di verificare i consensi sottoscritti può inviare una mail al seguente indirizzo:{' '}
                  <span style={{color: 'blue'}}>privacy@giocamondo.it</span>{' '}
                  L'accettazione è obbligatoria per poter procedere all'invio
                  dei dati.
                </p>
                <CheckboxField
                  name="privacyAccepted"
                  label="Dichiaro di aver preso visione della privacy policy"
                />

                <p>
                  Consenti il trattamento dei Tuoi dati personali per il
                  ricevimento della Newsletter aziendale, di comunicazioni
                  relative a nostri servizi, prodotti, promozioni per finalità
                  di marketing diretto?{' '}
                  <a href="https://www.iubenda.com/privacy-policy/883329">
                    Leggi qui
                  </a>{' '}
                  l'informativa estesa sulla privacy per maggiori dettagli.
                </p>
                <RadioButtons
                  name="marketingAccepted"
                  options={[
                    {label: 'Acconsento', value: true},
                    {label: 'Non Acconsento', value: false},
                  ]}
                />
              </Typography>

              <SecondarySubmitButton
                text="Registrati"
                disabled={submitting}
                loading={submitting}
              />

              <Link
                href="/login"
                display="block"
                align="center"
                variant="body2"
              >
                Hai già un account? Accedi ora
              </Link>
            </form>
          )}
        />
      </RegisterContainer>
    </>
  );
}

const RegisterContainer = styled(Container).attrs({
  maxWidth: 'md',
})`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const FormTitle = styled(Typography).attrs({
  variant: 'h2',
  align: 'center',
  color: 'textSecondary',
})`
  margin-top: 32px;
`;

const GridRow = styled(Grid).attrs({
  container: true,
  spacing: 2,
  alignItems: 'center',
})`
  margin: 4px 0;
`;

const Field = styled(TextField).attrs({fullWidth: true})``;

const Password = styled(PasswordField).attrs({fullWidth: true})``;

const DateField = styled(Field).attrs({
  type: 'date',
  InputLabelProps: {
    shrink: true,
  },
})``;

const FiscalCodeButton = styled(Button).attrs({
  variant: 'contained',
  size: 'small',
})`
  width: 101px;
  height: 30px;
`;
