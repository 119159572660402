import React, {useState, useEffect, useCallback} from 'react';
import VoucherItem from '../components/VoucherItem';
import {
  Container,
  EmptyText,
  ErrorAlert,
  Spinner,
} from '../utils/commonStyledComponents';
import {fetchVouchers} from '../utils/api';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow as Row,
  TableCell,
  Typography,
  Paper,
  MenuItem,
  Button as MuiButton,
  TextField,
} from '@material-ui/core';
import formatDate from 'date-fns/format';
import styled from 'styled-components';

const Vouchers = () => {
  const [vouchers, setVouchers] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const refresh = useCallback(async () => {
    try {
      const data = await fetchVouchers();
      // console.log("PRACTICE DATA");
      // console.log(data);
      setVouchers(data);
    } catch (e) {
      setShowErrorMessage(true);
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (showErrorMessage) {
    return <ErrorAlert>Si è verificato un errore di caricamento</ErrorAlert>;
  }

  if (! (vouchers)) {
    return <Spinner />;
  }

  return (
    <Container>
      <Table className="tb_voucher">
        <Head>
          <Row>
            <Cell>CODICE VOUCHER</Cell>
            <Cell>Valore Voucher</Cell>
            <Cell>Valore Consumato</Cell>
            <Cell>Sconto Integrativo</Cell>
            <Cell>Sconto Consumato</Cell>
            <Cell>Dettaglio</Cell>
            <Cell>Valore Totale</Cell>
            <Cell>Scadenza</Cell>
            <Cell>Riscattato</Cell>
            <Cell>Divisione</Cell>
          </Row>
        </Head>

        <Body>
          {vouchers.map((voucher, index) => (
            <Row key={index}>
              <Cell>{voucher.voucherCode}</Cell>
              <Cell>{(voucher.voucherValue / 100).toFixed(2)} €</Cell>
              <Cell>{(voucher.paymentMethodConsumed / 100).toFixed(2)} €</Cell>
              <Cell>{(voucher.discountValue / 100).toFixed(2)} €</Cell>
              <Cell>{(voucher.valueDiscountConsumed / 100).toFixed(2)} €</Cell>
              <Cell>{voucher.voucherDescription}</Cell>
              <Cell><b>{((voucher.voucherValue + voucher.discountValue) / 100).toFixed(2)} €</b></Cell>
              <Cell>{formatDate(new Date(voucher.expirationDate), 'dd/MM/yyyy')} </Cell>
              <Cell>{voucher.redeemed ? 'SI' : 'NO'}</Cell>
              <Cell>{voucher.division ? voucher.division : 'N/A'}</Cell>
            </Row>
          ))}
        </Body>
      </Table>
    </Container>
  );
};

export default Vouchers;

const Head = styled(TableHead)`
  width: 100%;
  background-color: ${props => props.theme.palette.table.head};
  color: ${props => props.theme.palette.text.primary};
`;

const Body = styled(TableBody)`
  color: ${props => props.theme.palette.text.secondary};
`;

const Cell = styled(TableCell).attrs({
  align: 'center',
})`
  color: inherit;
`;