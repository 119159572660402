import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import formatDate from 'date-fns/format';

const VoucherItem = ({voucher}) => (
  <Container>
    <Panel>
      <Summary>
        <Grid container>
          <GridItem item xs={12} md={2}>
            <b>{voucher.voucherCode}</b>
          </GridItem>

          <GridItem item xs={12} md={2}>
            {(voucher.voucherValue / 100).toFixed(2)} €
          </GridItem>

          <GridItem item xs={12} md={2}>
            {formatDate(new Date(voucher.expirationDate), 'dd/MM/yyyy')} 
          </GridItem>

          <GridItem item xs={12} md={4}>
            {voucher.voucherDescription}
          </GridItem>
        </Grid>
      </Summary>
    </Panel>
  </Container>
);

export default VoucherItem;

VoucherItem.propTypes = {
  voucher: PropTypes.shape({
    voucherCode: PropTypes.string,
    voucherType: PropTypes.string,
    voucherValue: PropTypes.number,
    expirationDate: PropTypes.string,
    description: PropTypes.string,
  }),
};

const Container = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const Panel = styled(ExpansionPanel).attrs({elevation: 0})`
  width: 100%;
`;

const expansionStyle = css`
  border-radius: 4px;
  border: 1px solid lightgray;
  //min-width: 900px; edit_20231020
`;

const Summary = styled(ExpansionPanelSummary).attrs({
  expandIcon: <ExpandMoreIcon />,
})`
  ${expansionStyle};
`;

const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  overflow: hidden;
  padding: 4px 16px;
  border-right: 1px solid lightgrey;
`;
