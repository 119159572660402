import React, {Component} from 'react';
import {PayPalButton} from 'react-paypal-button-v2';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';

import Button from '../Button';
import BankTransferOption from './BankTransferOption';
import {Spinner} from '../../utils/commonStyledComponents';
import {
    getPaypalClientId,
    finalizePaypalOrder,
    markPaymentError,
} from '../../utils/api';
import {NewPaymentButtonWrapper} from "./NewPaymentButtonWrapper";
import {CURRENCY} from "../../utils/constants";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";

class PaymentButton extends Component {
    state = {
        loading: false,
        clientId: '',
        bankTransferSelected: false,
    };


    componentDidMount() {
        if (this.props.skipPayButton) {
            this.getClientId();
        }
    }

    getClientId = async () => {
        this.setState({loading: true});
        try {
            const {CLIENT_ID: clientId} = await getPaypalClientId({
                practice: this.props.practice.practiceGUID,
            });

            this.setState({clientId});
        } catch (e) {
            console.error(e);
            this.setState({loading: false});
            this.props.displayAlert(GENERIC_ERROR, 'error');
        }
    };

    onButtonReady = () => this.setState({loading: false});

    onApprove = async (data, actions) => {
        this.setState({loading: true});

        return actions.order.get().then(() => this.onSuccess(data));
    };

    onSuccess = async orderId => {
        try {
            const response = await finalizePaypalOrder({
                practice: this.props.practice.practiceGUID,
                orderID: orderId,
                isFromMembership: true,
            });

            this.props.displayAlert(response);
            this.props.onComplete();
        } catch (e) {
            console.error(e);
            this.setState({clientId: '', loading: false});
            this.props.displayAlert(FINALIZE_PAYMENT_ERROR, 'error', 10000);
        }
    };

    onError = err => {
        try {
            markPaymentError({
                practice: this.props.practice.practiceGUID,
                response: err.message,
            });
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({clientId: '', loading: false});
            this.props.displayAlert(MARK_PAYMENT_ERROR, 'error');
        }
    };

    toggleBankTransferSelected = () =>
        this.setState({bankTransferSelected: !this.state.bankTransferSelected});

    render() {
        const {remainingAmount, practiceDivision} = this.props.practice;
        const {loading, clientId, bankTransferSelected} = this.state;
        let amount = remainingAmount;

        if (this.props.amount) {
            amount = this.props.amount;
        }

        if (this.state.clientId) {
            return (
                <Container>
                    {loading && <Spinner/>}
                    <HideableContainer hidden={loading}>
                        {!this.props.hideBankTransfer && <BankTransferOption
                            practiceDivision={practiceDivision}
                            toggleBankTransferSelected={this.toggleBankTransferSelected}
                            shortCode={this.props.practice.shortCode}
                        />}

                        <HideableContainer hidden={(bankTransferSelected || loading)}>
                            {!this.props.hideBankTransfer && <Text>Oppure tramite</Text>}
                            <PayPalScriptProvider
                                options={{
                                    "client-id": clientId,
                                    components: "buttons,funding-eligibility",
                                    currency: CURRENCY,
                                    'enable-funding': 'paylater,mybank',
                                    commit: true,
                                }}
                            >
                                <NewPaymentButtonWrapper
                                    currency={CURRENCY}
                                    showSpinner={true}
                                    amount={amount}
                                    onPaypalAnswered={(val) => {
                                        console.info("onPaypalAnswered", val);
                                    }}
                                    onPaypalPaidOrder={this.onSuccess}
                                    onMarkAsPaymentError={this.onError}
                                    acceptedPaymentMethods={['card']}
                                    onInit={this.onButtonReady}
                                />
                            </PayPalScriptProvider>
                        </HideableContainer>
                    </HideableContainer>
                </Container>
            );
        }

        return (
            this.props.isPracticePayable && !this.props.skipPayButton && <PayButton
                text="Effettua un nuovo pagamento&nbsp;+"
                onClick={this.getClientId}
                disabled={amount <= 0}
                loading={loading}
            />
        );
    }
}

PaymentButton.propTypes = {
    onComplete: PropTypes.func.isRequired,
    displayAlert: PropTypes.func.isRequired,
    practice: PropTypes.shape({
        practiceGUID: PropTypes.string.isRequired,
        practiceDivision: PropTypes.string.isRequired,
        remainingAmount: PropTypes.number.isRequired,
        shortCode: PropTypes.string.isRequired,
    }).isRequired,
    isPracticePayable: PropTypes.bool.isRequired,
    skipPayButton: PropTypes.bool,
    amount: PropTypes.number,
    hideBankTransfer: PropTypes.bool,
};

export default PaymentButton;

//error messages
const TextContainer = styled.div`
  & > p {
    margin: 0;
  }
`;
const GENERIC_ERROR = 'Si è verificato un errore di caricamento';

const FINALIZE_PAYMENT_ERROR = (
    <TextContainer>
        <b>Esito della transazione</b>
        <p>
            Per un problema tecnico, non è stato possibile registrare il tuo
            pagamento. Contattaci ad uno dei seguenti numeri
        </p>
        <p>
            0736.343440 - 0736.336339 dal Lun al Ven: 8:30 - 17:30 per ricevere assistenza e verificare la transazione.
        </p>
    </TextContainer>
);

const MARK_PAYMENT_ERROR = (
    <TextContainer>
        <b>Esito della transazione</b>
        <p>
            Siamo spiacenti, non è stato possibile completare l’operazione di
            pagamento. Riprova in un secondo momento.
        </p>
    </TextContainer>
);

//styled components
const Container = styled.div`
  max-width: 600px;
  min-width: 375px;
`;

const Text = styled(Typography).attrs({
    variant: 'caption',
    align: 'center',
    component: 'div',
    color: 'textSecondary',
})`
  margin: 24px 0 8px;
`;

const PayButton = styled(Button).attrs({
    variant: 'outlined',
    color: 'primary',
})`
  margin-top: 16px;
`;

const HideableContainer = styled.div`
  display: block;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;

  ${({hidden}) => `
    visibility: ${hidden ? 'hidden' : 'visible'};
    opacity: ${hidden ? 0 : 1};
  `};
`;
