import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';

import {Box, Dialog, Typography} from '@material-ui/core';
import {CalendarToday, Room} from '@material-ui/icons';
import Button from "./Button";
import {editServices, fetchAlternativeServicesByAlternativeLabel} from "../utils/api";
import {useHistory} from "react-router-dom";
import {customFormatDate, formatPrice} from "../utils/commonFunctions";
import {ErrorAlert, SuccessAlert, WarningAlert} from "../utils/commonStyledComponents";

const UpsellingPackageDetails = practice => {
    const history = useHistory();
    const [showDialog, setShowDialog] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);


    const {buyedPackage} = practice;

    const renderList = () => {
        if (!practice.serviceSnapshots?.length) {
            return <i>Nessun servizio acquistato</i>;
        }

        const groupedItems = practice.serviceSnapshots?.reduce((groups, item) => {
            const groupKey = item.itemGroup || "";
            groups[groupKey] = groups[groupKey] || [];
            groups[groupKey].push(item);
            return groups;
        }, {});

        const groupedList = Object.entries(groupedItems).map(([groupKey, items]) => ({
            groupName: groupKey,
            items
        }));

        return (
            <>
                <ListBox>
                    <ListTitle/>
                    <ListDetail>qt.</ListDetail>
                    <ListDetail>prezzo €</ListDetail>
                </ListBox>

                {groupedList.map((group, index) => {
                    return <>
                        {(group.groupName !== "") && <ItemGroupTitle>
                            <Subtitle>{group.groupName}</Subtitle>
                        </ItemGroupTitle>}

                        {group.items.map((item, index) => {
                            item.displayName = `${item.code || ''} ${item.name || ''}`;
                            return (
                                <ListRow key={index}>
                                    <ListBullet/>
                                    <ListTitle>{item.displayName}</ListTitle>
                                    <ListDetail>{item.quantity}</ListDetail>
                                    <ListDetail>{formatPrice(item.price)}</ListDetail>
                                </ListRow>
                            );
                        })}
                    </>;
                })}

            </>
        );
    };

    return (
        <>
            <Container>
                <DateBox>
                    Data di prenotazione:&nbsp;
                    <Text> {customFormatDate(practice.creationDate)} </Text>
                </DateBox>
                <TitleBox>
                    {buyedPackage.title}&nbsp;&nbsp;<Subtitle>[ID: {buyedPackage.webidpackage}]</Subtitle>
                </TitleBox>
                <Text>Cod: {buyedPackage.code}</Text>
                <IconBoxes>
                    <IconBox>
                        <CalendarToday/>
                        &nbsp;
                        <Text
                            variant="caption">{` dal ${customFormatDate(buyedPackage.periodFrom)} al ${customFormatDate(buyedPackage.periodTo)}`}</Text>
                    </IconBox>

                    <IconBox display="inline-flex" bgcolor="#f7f7f7;" alignItems="center">
                        <Room/>
                        &nbsp;
                        <Text variant="caption"> {practice.destination}</Text>
                    </IconBox>

                    <Text style={{display: "inline"}} variant="h6"> Costo
                        totale: {formatPrice(practice.totalCost)}</Text>
                </IconBoxes>

                <>
                    <Subtitle style={{display: "inline-flex"}}>Lista dei servizi acquistati</Subtitle>
                    {
                        practice.canBeUpsold ?
                            <OutlinedInlineButton
                                text="Personalizza la tua esperienza"
                                onClick={() => setShowDialog(true)}
                            />
                            :
                            <WarningAlert>Attenzione! Non ci sono servizi disponibili per effettuare una personalizzazione per questa prenotazione.</WarningAlert>
                    }
                    {showSuccessAlert && <SuccessAlert>Congratulazioni! La tua prenotazione è stata modificata con
                        successo</SuccessAlert>}
                </>

                {renderList()}
            </Container>

            <Dialog open={showDialog} onBackdropClick={() => setShowDialog(false)}>
                <DialogContent>
                    <Text>Procedendo alla modifica sarà possibile personalizzare la tua esperienza scegliendo nuovi servizi extra che miglioreranno il tuo soggiorno. Ti ricordiamo che la modifica di upselling prevede una rimodulazione del costo totale del soggiorno in base alle selezioni che si faranno.<br/><br/><Bold>Importante: per confermare le modifiche verrà richiesto di salvare, alle prenotazioni nello stato opzionato, di salvare e pagare online, alle prenotazioni nello stato confermato.</Bold><br/><br/>Concludendo questa azione riceverà una mail di conferma dell’avvenuta modifica ma si specifica che non verrà emessa alcuna nuova documentazione (es. nuovo contratto) in merito in quanto fanno fede i servizi pagati e presenti nella sezione pratiche/prenotazioni.</Text>
                    <Row>
                        <OutlinedButton
                            text="Procedi alla modifica"
                            onClick={() => history.push(`upsell-practice/${practice.id}`)}
                        />
                        <ContainedButton
                            text="Annulla"
                            onClick={() => setShowDialog(false)}
                        />
                    </Row>
                </DialogContent>
            </Dialog>
        </>
    );
};

UpsellingPackageDetails.propTypes = {
    buyedPackage: PropTypes.objectOf({
        webidpackage: PropTypes.string,
        periodFrom: PropTypes.string,
        periodTo: PropTypes.string,
        code: PropTypes.string,
    }),
    creationDate: PropTypes.string,
    destination: PropTypes.string,
    canBeUpsold: PropTypes.bool,
    totalCost: PropTypes.number,
    serviceSnapshots: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string,
            itemGroup: PropTypes.string,
            name: PropTypes.string,
            quantity: PropTypes.number,
            price: PropTypes.number,
        })
    ),
};

export default UpsellingPackageDetails;

const Container = styled.div`
  padding: 32px;
`;

const Text = styled(Typography).attrs({
    color: 'textPrimary',
    component: 'p',
})``;

const Subtitle = styled(Text)`
  font-size: 16px;
  font-weight: 700;
`;

const DateBox = styled(Box)`
  display: inline-flex;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 2px solid lightgrey;
  padding: 4px 0;
`;

const TitleBox = styled(Box)`
  display: flex;
  align-items: flex-end;
  font-weight: 800;
  font-size: 20px;
  margin-top: 12px;
`;

const IconBoxes = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`;

const IconBox = styled(Box)`
  display: inline-flex;
  background-color: #f7f7f7;
  align-items: center;
  width: 250px;
  margin-right: 8px;
  padding: 8px 12px;
  color: #3f51b4;
`;

const ItemGroupTitle = styled(Box)`
  display: inline-flex;
  background-color: #f7f7f7;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 8px 12px;
`;
const ListBox = styled(Box)`
  padding: 0 8px;
  display: flex;
  width: 600px;
  color: grey;
  font-size: 10px;
`;

const ListRow = styled(ListBox)`
  padding: 12px 8px;
  border-bottom: 1px solid lightgrey;
  color: black;
  font-size: 14px;
`;

const ListBullet = styled.div`
  height: 16px;
  min-width: 16px;
  margin-top: 2px;
  border-radius: 50%;
  background-color: #f50057;
`;


const ListTitle = styled(Box)`
  flex-grow: 1;
  padding: 0 32px 0 16px;
`;

const ListDetail = styled(Box)`
  min-width: 100px;
  display: inline-flex;
  justify-content: center;
`;


const OutlinedButton = styled(Button).attrs({
    variant: 'outlined',
    color: 'primary',
})``;


const ContainedButton = styled(Button).attrs({
    variant: 'contained',
    color: 'primary',
})`
  color: white;
`;


const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 32px;
`;

const DialogContent = styled(Typography).attrs({component: 'div'})`
  padding: 32px;
`;

const Bold = styled(Text)`
  font-weight: 600;
`;

const OutlinedInlineButton = styled(Button).attrs({
    variant: 'outlined',
    color: 'primary',
})`
  display: inline-flex !important;
  margin-left: 8px !important;
  padding: 0 15px;
`;
