import {
  AppBar,
  Button as MuiButton,
  Dialog,
  Slide,
  Toolbar,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import styledComponents from 'styled-components';
import Button from '../components/Button';
import {RadioButtons, TextField} from '../components/Form';
import {FormSectionTitle} from '../utils/commonStyledComponents';
import config from '../utils/config';
import {useUser} from '../utils/UserSession';

/**
 * @param number division
 * @returns {Promise<{ question: string, id: string, type: 'radio' | 'text' }[]>}
 */
const fetchQuestions = division => {
  return fetch(
    `${config.signServer}modulob/division/${division}/fetch-questions`
  ).then(response => {
    if (response.ok === false) {
      throw new Error('Invalid request');
    }

    return response.json();
  });
};

const showPreview = (division, responses, participant, author) => {
  return fetch(`${config.signServer}modulob/division/${division}/preview`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      responses: responses,
      participant,
      author,
    }),
  }).then(response => {
    if (response.ok === false) {
      throw new Error('Invalid request');
    }

    return response.json();
  });
};

const startSignProcedure = (
  division,
  documentId,
  responses,
  participant,
  author
) => {
  return fetch(`${config.signServer}modulob/division/${division}/sign`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      documentId,
      responses: responses,
      participant,
      author,
    }),
  }).then(response => {
    if (response.ok === false) {
      throw new Error('Invalid request');
    }

    return response.json();
  });
};

/**
 * @param {{ division: number, documentId: string }} props
 */
export default function ModuloB({division, documentId, participant, onClose}) {
  const {user} = useUser();

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [step, setStep] = useState('form');
  const [preview, setPreview] = useState('');
  const [signId, setSignId] = useState('');

  useEffect(() => {
    fetchQuestions(division)
      .then(data => {
        setQuestions(data);

        setResponses(
          Object.fromEntries(
            data.map(q => [q.id, q.type === 'radio' ? undefined : ''])
          )
        );
      })
      .then(() => setLoading(false));
  }, [division]);

  const handleFormSubmit = async data => {
    setResponses(data);
    await showPreview(
      division,
      data,
      {
        firstName: participant.customer.firstName,
        lastName: participant.customer.lastName,
        phone: participant.customer.mobilePhone,
        email: null,
        holidayCentre: participant.holidayCentre,
        dates: participant.dates,
      },
      {
        firstName: user.firstName,
        lastName: user.lastName,
        relationship: participant.relation,
        email: user.email1,
        phone: user.mobilePhone,
      }
    )
      // .then(data => setPreview(data.html))
      .then(() => startSign(data));
  };

  const startSign = responses => {
    startSignProcedure(
      division,
      documentId,
      responses,
      {
        firstName: participant.customer.firstName,
        lastName: participant.customer.lastName,
        phone: participant.customer.mobilePhone,
        email: null,
        holidayCentre: participant.holidayCentre,
        dates: participant.dates,
      },
      {
        firstName: user.firstName,
        lastName: user.lastName,
        relationship: participant.relation,
        email: user.email1,
        phone: user.mobilePhone,
      }
    ).then(data => {
      setSignId(data.procedure.members[0].id);
      setStep('sign');
    });
  };

  const endSignProcess = () => {};

  let content = null;

  if (step === 'form') {
    content = (
      <>
        <AppBar sx={{position: 'relative'}}>
          <Toolbar>
            <MuiButton
              className="btn-close"
              autoFocus
              color="inherit"
              onClick={onClose}
            >
              Chiudi
            </MuiButton>
          </Toolbar>
        </AppBar>

        <div style={{padding: '50px 20px 10px'}}>
          <FormSectionTitle style={{textAlign: 'center', marginBottom: 30}}>
            MODULO CONSENSO GENITORE
          </FormSectionTitle>
          <Form
            initialValues={responses}
            onSubmit={handleFormSubmit}
            render={({handleSubmit, submitting}) => (
              <form
                onSubmit={handleSubmit}
                style={{margin: '0 auto', maxWidth: '880px'}}
              >
                {questions.map((q, index) => (
                  <Question
                    key={q.id}
                    index={index + 1}
                    question={q.question}
                    id={q.id}
                    type={q.type}
                  />
                ))}

                <Button
                  type="submit"
                  loading={submitting}
                  variant="contained"
                  color="primary"
                  text="Firma il modulo"
                />
              </form>
            )}
          />
        </div>
      </>
    );
  }

  // if (step === 'preview') {
  //   content = (
  //     <>
  //       <AppBar sx={{position: 'relative'}}>
  //         <Toolbar>
  //           <Button
  //             className="btn-close"
  //             autoFocus
  //             color="inherit"
  //             onClick={onClose}
  //           >
  //             Chiudi
  //           </Button>
  //         </Toolbar>
  //       </AppBar>

  //       <div style={{padding: '50px 20px 10px'}}>
  //         <h1>Preview</h1>

  //         <iframe
  //           style={{width: '90vw', height: '90vh'}}
  //           src={`data:text/html,${preview}`}
  //         />

  //         <button onClick={() => setStep('form')}>Back</button>
  //         <button onClick={startSign}>Sign</button>
  //       </div>
  //     </>
  //   );
  // }

  if (step === 'sign') {
    content = (
      <>
        <AppBar sx={{position: 'relative'}}>
          <Toolbar>
            <MuiButton
              className="btn-close"
              autoFocus
              color="inherit"
              onClick={onClose}
            >
              Chiudi
            </MuiButton>
          </Toolbar>
        </AppBar>

        <div style={{paddingTop: '70px'}}>
          <iframe
            style={{width: '100%', height: 'calc(100vh - 74px)', border: 0}}
            src={config.yousignUrl + signId}
          />
        </div>
      </>
    );
  }

  return (
    <Dialog
      className="modulob-dialog"
      fullScreen
      open={true}
      onClose={endSignProcess}
      // TransitionComponent={Transition}
    >
      {content}
    </Dialog>
  );
}

/**
 * @param {{ question: string, index: number, id: string, type: 'radio' | 'text' }} props
 */
function Question({question, index, id, type}) {
  if (type === 'text') {
    return <TextQuestion index={index} question={question} id={id} />;
  } else if (type === 'radio') {
    return <RadioQuestion index={index} question={question} id={id} />;
  } else {
    throw new Error('Invalid question');
  }
}

/**
 * @param {{ question: string, index: number, id: string }} props
 */
function RadioQuestion({question, index, id}) {
  return (
    <div style={{marginBottom: 30}}>
      <p style={{margin: 0}}>
        <strong>Domanda {index}:</strong> {question}
      </p>

      <RadioButtons
        name={id}
        options={[
          {value: true, label: 'Sì'},
          {value: false, label: 'No'},
        ]}
        validate={v => (v === undefined ? 'Campo obbligatorio' : undefined)}
      />
    </div>
  );
}

/**
 * @param {{ question: string, index: number, id: string }} props
 */
function TextQuestion({question, index, id}) {
  return (
    <div style={{marginBottom: 30}}>
      <p style={{margin: 0}}>
        <strong>Domanda {index}:</strong> {question}
      </p>{' '}
      <Field name={id} label="Risposta" />
    </div>
  );
}

const Field = styledComponents(TextField).attrs({fullWidth: true})``;
