import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import ExtraServicesItem from './ExtraServicesItemMobile'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import styled from "styled-components";
import Button from "../Button";

const useStyles = makeStyles(() => ({
    pricingTable: {
        padding: '3%',
        background: '#fff',
        borderRadius: '5px',
        //border: 'solid 3px #236fbf',
        boxShadow: '0 0px 40px #ccc',
        margin: '10px 0',
    },
    item: {
        margin: '8px 0',
    },
    header: {
        marginBottom: '24px',
    },
    selected: {
        margin: '8px 0',
        border: '5px solid #ffc41a'
    }
}))

export default function ExtraServiceMobile(props) {
    const classes = useStyles()
    const {selectedExtraServices, onSelectExtraService, extraservice, onClearSelection, initialExtraServices} = props

    const {
        title,
        subtitle,
        description,
        extraservices,
        closureText,
        isNew,
    } = extraservice

    const groupName = title + subtitle;
    //Controllo se il groupo ha almeno un servizio che è stato selezionato
    const hasAtLeastOneItemSelected = extraservices.map(x => x.value).some(item => selectedExtraServices.map(y => y.value).includes(item));
    return (
        <div
            key={groupName}
            className={clsx(classes.pricingTable, 'v-divider')}>
            <div className={classes.header}>
                <Typography variant="h6">{title}</Typography>
                <Typography variat="body">{description}</Typography>
            </div>
            {isNew && hasAtLeastOneItemSelected && onClearSelection && <ContainedButton
                text="Annulla selezione"
                onClick={() => onClearSelection(groupName)}
            />}
            <div>
                {extraservices.length > 0
                    ? extraservices.map(xs => {
                        const cl = initialExtraServices ? initialExtraServices.includes(xs.value) ? classes.selected : classes.item : null ;
                        return (
                            <div className={cl}>
                                <ExtraServicesItem
                                    key={xs.value}
                                    data={xs}
                                    siblingNumber={extraservices.length - 1}
                                    selectedExtraServices={selectedExtraServices}
                                    onSelectExtraService={onSelectExtraService}
                                />
                            </div>
                        );
                    })
                    : null}
            </div>
            {!!closureText && (
                <div>
                    <footer
                        className="extra-closure-text"
                        dangerouslySetInnerHTML={{
                            __html: closureText,
                        }}
                    />
                </div>
            )}
        </div>
    )
}

ExtraServiceMobile.propTypes = {
    extraservice: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        closureText: PropTypes.string,
        extraservices: PropTypes.array,
    }).isRequired,
    selectedExtraServices: PropTypes.array.isRequired,
    initialExtraServices: PropTypes.array,
    onSelectExtraService: PropTypes.func.isRequired,
}


const ContainedButton = styled(Button).attrs({
    variant: 'contained',
    color: 'primary',
})`
  color: white;
`;
