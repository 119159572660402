let config;

if (process.env.REACT_APP_MEMBERSHIP_ENV === 'production') {
  config = {
    apiUrl: 'https://gestionale.giocamondo.it/api',
    shopUrl: 'https://shop.giocamondo.it',
    signServer: 'https://modulob.giocamondo.it/',
    yousignUrl:
      'https://webapp.yousign.com/procedure/sign?signatureUi=/signature_uis/c2145030-3c09-4c2e-b182-cc0f542f9973&members=',
  };
} else if (process.env.REACT_APP_MEMBERSHIP_ENV === 'staging') {
  config = {
    apiUrl: 'https://staging.giocamondo.it/api',
    shopUrl: 'https://shop.staging.giocamondo.it',
    signServer: 'https://staging.modulob.giocamondo.it/',
    yousignUrl: 'https://staging-app.yousign.com/procedure/sign?members=',
  };
} else if (process.env.REACT_APP_MEMBERSHIP_ENV === 'staging-bis') {
  config = {
    apiUrl: 'https://staging-bis.giocamondo.it/api',
    shopUrl: 'https://shop-bis.staging.giocamondo.it',
  };
} else {
  config = {
    /* apiUrl: 'http://localhost:8080/index.php/api',
    shopUrl: 'http://localhost:8080/shop',
    signServer: 'http://localhost:8081/', */
    apiUrl: 'https://staging.giocamondo.it/api',
    shopUrl: 'https://shop.staging.giocamondo.it',
    signServer: 'https://staging.modulob.giocamondo.it/',
    yousignUrl: 'https://staging-app.yousign.com/procedure/sign?members=',
  };
}

export default config;
