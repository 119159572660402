//practice statuses
export const PRACTICE_STATUS_OPZIONATO = 'opzionato';
export const PRACTICE_STATUS_CONFERMATO = 'confermato';
export const PRACTICE_STATUS_IN_LAVORAZIONE = 'in lavorazione';
export const PRACTICE_STATUS_PREVENTIVATO = 'preventivato';
export const PRACTICE_STATUS_ANNULLATO = 'annullato';
export const PRACTICE_STATUS_CONCLUSO = 'concluso';
export const PRACTICE_STATUS_STAND_BY = 'stand-by';

// practice types
export const PRACTICE_TYPE_PRIVATO = 'PRIVATO';
export const PRACTICE_TYPE_INPS = 'INPS';
export const PRACTICE_TYPE_ALTRO = 'ALTRO';
export const PRACTICE_TYPE_AZIENDA_CONVENZIONATA = 'AZIENDA CONVENZIONATA';
export const PRACTICE_TYPE_WELFARE = 'WELFARE';
export const PRACTICE_TYPE_INPSNUOVO = 'INPS';

//practice division
export const PRACTICE_DIVISION_GIOCAMONDO_STUDY = 'Giocamondo Study';
export const PRACTICE_DIVISION_LA_MIA_ESTATE = 'La Mia Estate';

//cluser codes
export const CLUSTER_CODE_BLUE = 'blue';
export const CLUSTER_CODE_SILVER = 'silver';
export const CLUSTER_CODE_GOLD = 'gold';
export const CLUSTER_CODE_DIAMOND = 'diamond';

//memeber relationship types
export const RELATION_GENITORE = 'genitore';
export const RELATION_FIGLIO = 'figlio';
export const RELATION_COMPAGNO = 'compagno';
export const RELATION_CONIUGE = 'coniuge';
export const RELATION_ALTRO = 'altro';

export const CURRENCY = 'EUR';
export const PAYPAL_MIN = 0;
export const PAYPAL_MAX = 500;
