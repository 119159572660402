import React, {Component} from 'react';

import PracticeItem from '../components/PracticeItem';
import PaymentDetails from '../components/Payments/PaymentDetails';
import StatusAlert from '../components/StatusAlert';
import {
  Container,
  EmptyText,
  ErrorAlert,
  Spinner,
} from '../utils/commonStyledComponents';
import {fetchPracticesPayments, fetchPracticeInvoiceDocuments} from '../utils/api';
import {filterPracticesByYear, getPracticeYears} from "../utils/commonFunctions";
import YearItem from "../components/YearItem";

function getInvoicesData (practice = {}, fiscalDocs = {}) {
  const getInvoicesEmittedAmount = (invoices) => {
    if (invoices.length === 0) {
      return 0
    }

    return invoices.reduce(
      (total, {totalamount}) =>
        totalamount ? total + parseFloat(totalamount) : total,
      0
    )
  }

  const totalAmount = practice.practicePrice ? parseInt(practice.practicePrice, 10) / 100 : null

  const costoINPS = practice.costoINPS
    ? -parseInt(practice.costoINPS, 10) / 100
    : 0
  const invoicesTotalAmount = totalAmount ? totalAmount + costoINPS : null

  const invoicesEmittedAmount = getInvoicesEmittedAmount(fiscalDocs.rows || [])
  const invoicesDueAmount = invoicesTotalAmount
      ? invoicesTotalAmount - invoicesEmittedAmount
      : null

  return {
    invoicesTotalAmount,
    invoicesEmittedAmount,
    invoicesDueAmount
  }
}

class Payments extends Component {
  state = {
    practices: null,
    loading: true,
    showErrorMessage: false,
    statusAlert: null,
  };

  componentDidMount() {
    this.getPractices();
  }

  getPractices = async () => {
    this.setState({loading: true});
    try {
      const practicesBasic = await fetchPracticesPayments();
      const practices = practicesBasic.map(practice => ({
        ...practice,
        loadingInvoiceData: true,
        invoiceData: null,
        invoiceDataError: null,
      }))
      this.setState({
        practices,
        loading: false
      });
      // console.log('practices done')
      for (const practice of practices) {
        // console.log('getting fiscal docs for practice ' + practice.id)
        try {
          const fiscalDocs = await fetchPracticeInvoiceDocuments(practice.id)
          const result = getInvoicesData(practice, fiscalDocs)
          this.setState((state) => {
            return {
              practices: state.practices.map(p => {
                if (p.id !== practice.id) {
                  return p
                }
                return {
                  ...practice,
                  invoiceData: result,
                  loadingInvoiceData: false,
                  invoiceDataError: null,
                  fiscalDocs: fiscalDocs
                }
              })
            };
          });
        } catch (error) {
          console.error('Error trying to get fiscal docs of practice ' + practice.id)
          console.error(error)
          this.setState((state) => {
            return {
              practices: state.practices.map(p => {
                if (p.id !== practice.id) {
                  return p
                }
                return {
                  ...practice,
                  invoiceData: {},
                  loadingInvoiceData: false,
                  invoiceDataError: error
                }
              })
            };
          });
        }
      }
    } catch (e) {
      this.setState({showErrorMessage: true, loading: false});
    }
  };

  displayAlert = (text, severity, duration) => {
    this.setState({
      statusAlert: {
        text,
        severity,
      },
    });

    setTimeout(() => {
      this.setState({statusAlert: null});
    }, duration || 4000);
  };

  renderContent() {
    const {practices, loading, showErrorMessage} = this.state;

    if (showErrorMessage) {
      return <ErrorAlert>Si è verificato un errore di caricamento</ErrorAlert>;
    }

    if (loading) {
      return <Spinner />;
    }

    // const hasPayment = practices.some(el => el.practicePayments.length);
    const practicesExist = practices.length;
    if (!practicesExist) {
      return <EmptyText>Nessun pagamento disponibile</EmptyText>;
    }
    const years = getPracticeYears(practices);

    return (
      <Container>
          {years.map(y => {
              const filteredPractices = filterPracticesByYear(practices, y);
              return <YearItem key={y} year={y}>
                  {filteredPractices.map((practice, index) => {
                      return (
                          <PracticeItem practice={practice} key={index}>
                              <PaymentDetails
                                  practice={practice}
                                  updatePractices={this.getPractices}
                                  displayAlert={this.displayAlert}
                              />
                          </PracticeItem>
                      );
                  })}
              </YearItem>
          })}
      </Container>
    );
  }

  render() {
    const {statusAlert} = this.state;

    return (
      <>
        {this.renderContent()}
        <StatusAlert visible={!!statusAlert} severity={statusAlert?.severity}>
          {statusAlert?.text}
        </StatusAlert>
      </>
    );
  }
}

export default Payments;
