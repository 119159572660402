import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
// import Grid from '@material-ui/core/Grid'
// import {PaymentContext} from 'contexts/PaymentContext'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import {green} from '@material-ui/core/colors'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const GreenRadio = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />)

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500,
        fontSize: '1rem',
        padding: '10px 5px',
        textTransform: 'uppercase',
    },
    value: {
        background: '#efefef',
        padding: '0 5px',
        fontSize: '1.3rem',
        fontWeight: 600,
        margin: '2px 2px 2px auto',
        lineHeight: '1.3',
        transition: '.2s',
        whiteSpace: 'nowrap',
        height: 'fit-content',
    },
    selectedFrame: {
        borderColor: '#ffc41a',
        borderWidth: '8px',
        zIndex: 1,
    },
    suggestedFrame: {
        borderColor: '#0acd90',
        // transform: 'scale(1.05)'
    },
    suggestedBox: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        margin: 'auto',
    },
    suggestedBoxLabel: {
        display: 'block',
        background: '#0acd90',
        color: '#fff',
        margin: '0',
        fontWeight: 'bold',
        padding: '2px',
        // fontSize: 'x-small'
    },
}))

export default function ExtraServicesItemMobile(props) {
    const classes = useStyles()
    // const [paymentState, setPaymentState] = useContext(PaymentContext)
    const {data, selectedExtraServices, onSelectExtraService} = props

    const value = data.isVisualPrice ? data.visualPriceAmount : data.realvalue
    const handleSelection = () => {
        // setPaymentState(state => ({
        //   ...state,
        //   isVisualPrice: data.isVisualPrice || false,
        // }))
        onSelectExtraService(data.value, parseInt(data.serviceId))
    }
    const isSelected =
        selectedExtraServices && (selectedExtraServices?.filter((x) => x.value === data.value)?.length > 0);
    const isSuggested = !!data.suggested

    return (
        <div className="extra-accordion">
            <Accordion
                variant="outlined"
                square="false"
                expanded={isSelected}
                onClick={handleSelection}
                className={clsx({
                    [classes.suggestedFrame]: isSuggested && !isSelected,
                    [classes.selectedFrame]: isSelected,
                })}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header">
                    {isSuggested && (
                        <div className={classes.suggestedBox}>
                            <small className={classes.suggestedBoxLabel}>PIÙ VENDUTO</small>
                        </div>
                    )}
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={handleSelection}
                        onFocus={event => event.stopPropagation()}
                        control={<GreenRadio/>}
                        checked={isSelected}
                        // label={data.label}
                    />
                    <Typography className={classes.title}>{data.label}</Typography>
                    <div className={classes.value}>€ {value / 100}</div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="textSecondary">{data.description}</Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

ExtraServicesItemMobile.propTypes = {
    data: PropTypes.shape({
        value: PropTypes.number.isRequired,
        suggested: PropTypes.bool,
        label: PropTypes.string.isRequired,
        description: PropTypes.string,
        realvalue: PropTypes.number.isRequired,
    }).isRequired,
    selectedExtraServices: PropTypes.arrayOf(PropTypes.number).isRequired,
    onSelectExtraService: PropTypes.func.isRequired,
}
