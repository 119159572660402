import React, {useEffect, useState} from 'react';
import {ErrorAlert, FormTitle, InfoAlert, Spinner, SuccessAlert,} from '../utils/commonStyledComponents';
import {
    fetchAlternativeServicesByAlternativeLabel,
    fetchPractice, finalizePaypalOrder,
    getPaypalClientId,
    upsellPractice
} from '../utils/api';
import {useHistory, useParams} from "react-router-dom";
import styled from "styled-components";
import {Grid, Link, Paper, Typography} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import Button from "../components/Button";
import ExtraServiceMobile from "../components/ExtraServices/ExtraServiceMobile";
import {formatPrice} from "../utils/commonFunctions";
import {CURRENCY, PRACTICE_STATUS_CONFERMATO} from "../utils/constants";
import StatusAlert from "../components/StatusAlert";
import {NewPaymentButtonWrapper} from "../components/Payments/NewPaymentButtonWrapper";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";

const UpsellPractice = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [showPaymentSection, setShowPaymentSection] = useState(false);
    const [toPay, setToPay] = useState(false);
    const [initialExtraServices, setInitialExtraServices] = useState([]);
    const [statusAlertPayment, setStatusAlertPayment] = useState(null);
    const [showPracticeError, setShowPracticeError] = useState(false);
    const [showPracticeErrorMessage, setShowPracticeErrorMessage] = useState(null);
    const [showPracticeSuccess, setShowPracticeSuccess] = useState(false);
    const [isExtraServicesLoading, setIsExtraServicesLoading] = useState(false);
    const [practice, setPractice] = useState(null);
    const [extraServicesWrapper, setExtraServicesWrapper] = useState([]);
    const [selectedExtraServices, setSelectedExtraServices] = useState([]);
    const [initialValues, setInitialValues] = useState([]);
    const [newPackageItems, setNewPackageItems] = useState([]);
    const [submitText, setSubmitText] = useState("Salva modifica");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [paypalClientId, setPaypalClientId] = useState(null);
    const [errorMessage, setErrorMessage] = useState("Si è verificato un errore di caricamento");
    const {id} = useParams();

    /*START PAYMENT HANDLERS*/
    const onPaypalPaidOrder = async orderId => {
        try {
            const response = await finalizePaypalOrder({
                practice: practice.practiceGUID,
                orderID: orderId,
                isFromMembership: true,
            })
            console.info({response})
            await finalizePayment();
            return response
        } catch (error) {
            console.error(error)
        }
    }
    /*END PAYMENT HANDLERS*/

    const buildPayload = () => ({
        "practiceId": practice.id,
        "newPackageId": practice.buyedPackage.id,
        "newPackageItems": newPackageItems,
    })

    const filterExtraServicesFunction = (es, price) => (es.realvalue >= price) && es.canBeUpsold && (new Date(es.upsellingExpirationDate) >= new Date())

    const fetchExtraServices = async () => {
        try {
            setIsExtraServicesLoading(true);
            let alternatives = new Array(10)
                .fill(0)
                .map((p, index) => `Extra${index + 1}`);
            const participationNames = {1: 'PVT', 2: 'INPS', 4: 'AZ', 6: 'INPSN'};
            alternatives = alternatives.concat(alternatives.map(v => `${v}:${participationNames[practice.practiceType]}`))
            const promises = await Promise.all(
                alternatives.map((x) => fetchAlternativeServicesByAlternativeLabel(
                    x,
                    practice.practiceProductDivisionId,
                    practice.buyedPackage.id,
                    practice.practiceDivisionId
                ))
            );
            const newExtraServices = [...promises.filter((x) => x.val)];

            //Filtro tutti i servizi attuali della pratica che hanno almeno un alternative label
            const _currentExtraServices = [...practice.serviceSnapshots.filter(x => x.alternativeLabel)];
            setInitialExtraServices(_currentExtraServices);


            let _selectedExtraServices = [];

            //Scorro tutti i servizi ottenuti
            let moreExpensiveExtraServices = newExtraServices.map(extraService => {
                //Per ogni extraService, controllo se tra quelli già selezionati nella pratica, ce n'è almeno uno
                const currExtraSer = _currentExtraServices.find(x => x.alternativeLabel === extraService.alternativeLabel);

                if (currExtraSer) {
                    //Tra i servizi della pratica c'è almeno un'opzione selezionata
                    //Devo quindi selezionare solo le opzioni che hanno prezzo maggiore o uguale a quello del servizio selezionato
                    //Per farlo clono l'oggetto
                    //Prelevo in primis i servizi che rispettano la condizione
                    const arrayResult = extraService.val?.extraservices?.filter(x => filterExtraServicesFunction(x, currExtraSer.price));
                    //Se questo array ha un solo elemento, significa che è quello selezionato e non c'è possibilità di fare upselling.
                    //Quindi controllo che abbia almeno due elementi. In questo uno è quello selezionato, tutti gli altri sono di upselling

                    if (arrayResult.length > 1) {
                        //Ne approfitto e salvo quello selezionato nell'array opportuno
                        const item = arrayResult.find(y => parseInt(y.serviceId) === currExtraSer.service.id);
                        _selectedExtraServices.push({
                            groupName: extraService.val.title + extraService.val.subtitle,
                            value: item?.value,
                            serviceId: parseInt(item?.serviceId),
                            alternativeLabel: extraService.alternativeLabel,
                        })
                        return {
                            alternativeLabel: extraService.alternativeLabel,
                            val: {
                                ...extraService.val,
                                extraservices: arrayResult,
                            }
                        }
                    } else {
                        return null;
                    }
                } else {
                    //Tra i servizi della pratica non c'è questo servizio selezionato, quindi devo proporli tutti
                    //Aggiungo il parametro new che serve per indicare che questi servizi non sono proprio presenti nella pratica
                    //Quindi l'utente può potenzialmente togliere la spunta da tutto
                    extraService.val.extraservices = [...extraService.val.extraservices.filter(x => filterExtraServicesFunction(x, 0))];
                    extraService.val.isNew = true;
                    return {...extraService};
                }
            }).filter(Boolean);
            //Imposto ora i servizi già selezionati
            setSelectedExtraServices(_selectedExtraServices);
            //Salvo i servizi già selezionati anche in un'altra variabile per determinare poi il totale
            setInitialValues([..._selectedExtraServices.map(x => x.value)?.filter(y => y)]);

            setExtraServicesWrapper(moreExpensiveExtraServices);

            if (moreExpensiveExtraServices.length === 0) {
                setShowPracticeError(true);
                setShowPracticeErrorMessage("Non ci sono servizi aggiuntivi acquistabili in questa pratica");
            }
        } catch (e) {
            console.error(e);
            setShowErrorMessage(true);
        } finally {
            setIsExtraServicesLoading(false);
        }
    }

    const finalizePayment = async () => {
        try {
            setLoading(true);
            await upsellPractice(buildPayload());
            setShowPracticeSuccess(true);
        } catch (e) {
            console.error(e);
            setShowPracticeError(true);
            setShowPracticeErrorMessage("Si è verificato un errore durante la modifica della pratica. Contatta il servizio di assistenza per ulteriori informazioni");
        } finally {
            setLoading(false);
            setShowPaymentSection(false);
            setPaypalClientId(null);
        }
    }

    const onSubmit = async () => {
        try {
            if (practice.mustPay) {
                setPaymentLoading(true);
                const result = await getPaypalClientId({
                    practice: practice.practiceGUID,
                });
                if (result && result.CLIENT_ID) {
                    setShowPaymentSection(true);
                    setPaypalClientId(result.CLIENT_ID);
                } else {
                    setShowPracticeError(true);
                    setShowPracticeErrorMessage("C'è stato qualche problema durante l'elaborazione del pagamento");
                    setShowPaymentSection(false);
                }
            } else {
                setLoading(true);
                await upsellPractice(buildPayload());
                setShowPracticeSuccess(true);
            }
        } catch (e) {
            console.error(e);
            setShowPracticeError(true);
            setShowPracticeErrorMessage("Si è verificato un errore durante la modifica della pratica. Contatta il servizio di assistenza per ulteriori informazioni");
            setShowPaymentSection(false);
        } finally {
            setLoading(false);
            setPaymentLoading(false);
        }
    };

    const getPractice = async () => {
        try {
            const practice = await fetchPractice(id);

            if (practice.canBeUpsold) {
                setPractice(practice);
                practice.mustPay = (practice.readableStatus.toLowerCase() === PRACTICE_STATUS_CONFERMATO);
                practice.mustPay && setSubmitText("Salva e paga online");
            } else {
                setErrorMessage("Non è possibile acquistare servizi aggiuntivi sulla pratica selezionata!");
                setShowErrorMessage(true);
            }
        } catch (e) {
            setShowErrorMessage(true);
        }
    }


    useEffect(() => {
        if (selectedExtraServices && (selectedExtraServices.length > 0)) {
            let _newPackageItems = [...selectedExtraServices.map(x => x.value)];
            _newPackageItems = _newPackageItems.filter(x => !initialValues.includes(x));
            setNewPackageItems(_newPackageItems);

            //Filtro solo i gruppi che hanno un elemento selezionato
            const extraServiceWrapperFiltered = extraServicesWrapper.filter(x => selectedExtraServices.map(y => y.alternativeLabel).includes(x.alternativeLabel));

            //Setto il totale da pagare
            let totalToPay = 0;
            extraServiceWrapperFiltered.forEach(group => {
                const {val, alternativeLabel} = group;
                const {extraservices} = val;

                const service = selectedExtraServices.find(x => x.alternativeLabel === alternativeLabel);
                const currentExtraServiceSelected = extraservices.find(x => service.serviceId === parseInt(x.serviceId));
                const currentExtraServiceSelectedPrice = currentExtraServiceSelected?.realvalue;

                //Il valore assume zero quando initialService è null, ovvero l'utente non ha mai selezionato alcun componente all'interno di questo gruppo
                let initialSelectedExtraServicePrice = 0;
                const initialService = initialExtraServices.find(x => x.alternativeLabel === alternativeLabel);
                if (initialService) {
                    const serviceAlreadySelected = extraservices.find(x => initialService.service.id === parseInt(x.serviceId));
                    initialSelectedExtraServicePrice = serviceAlreadySelected?.realvalue;
                } else {
                    //Vuol dire che in questo gruppo non è mai stato selezionato alcun elemento
                }
                const totalGroupToPay = currentExtraServiceSelectedPrice - initialSelectedExtraServicePrice;
                totalToPay += totalGroupToPay
            });

            setToPay(totalToPay);
        }
    }, [selectedExtraServices]);

    useEffect(() => {
        if (!practice) getPractice()
    }, []);

    useEffect(() => {
        if (practice) fetchExtraServices();
    }, [practice]);

    if (showErrorMessage || !id) {
        return <ErrorAlert>{errorMessage}</ErrorAlert>;
    }

    if (!practice || loading) {
        return <Spinner/>;
    }


    //Rimuove tutte le selezioni del gruppo
    const onClearSelection = groupName => setSelectedExtraServices([...selectedExtraServices.filter(x => x.groupName !== groupName)])

    const onSelectExtraService = (value, extraServiceGroup, serviceId) => {
        //Prelevo uno pseudo valore univoco per identificare se il servizio fa parte o no di un gruppo già selezionato
        const groupName = extraServiceGroup.val.title + extraServiceGroup.val.subtitle;
        //Salvo in una variabile una copia dei servizi già selezionati
        let newSelectedExtraServices = [...selectedExtraServices];
        //Controllo innanzitutto se esiste già un servizio nello stesso gruppo di quello selezionato
        const extraServiceFoundInSameGroup = newSelectedExtraServices.some(x => x.groupName === groupName);

        if (extraServiceFoundInSameGroup) {
            //All'interno dell'array ho già un servizio dello stesso gruppo
            //Adesso controllo che io non abbia cliccato sullo stesso elemento
            const sameExtraServiceSelectedInSameGroup = value === newSelectedExtraServices.find(x => x.groupName === groupName)?.value;
            if (!sameExtraServiceSelectedInSameGroup) {
                //Ho selezionato un altro elemento dello stesso gruppo
                //quindi questo va sostituito con quello inserito
                //Salvo quindi nel nuovo array, quello senza questo elemento
                newSelectedExtraServices = newSelectedExtraServices.filter(x => x.groupName !== groupName);
                //Aggiungo in questo array quello appena selezionato
                newSelectedExtraServices.push({
                    groupName,
                    value: value,
                    serviceId: serviceId,
                    alternativeLabel: extraServiceGroup.alternativeLabel,
                });
                setSelectedExtraServices(newSelectedExtraServices);
            }
        } else {
            //Non ho ancora selezionato nessun elemento dello stesso gruppo
            //Posso quindi aggiungerlo senza problemi
            newSelectedExtraServices.push({
                groupName,
                value: value,
                serviceId: serviceId,
                alternativeLabel: extraServiceGroup.alternativeLabel,
            });
            setSelectedExtraServices(newSelectedExtraServices);
        }
    }

    const renderPriceWithSubmit = () => {
        return !showPracticeSuccess && (newPackageItems?.length > 0) && !showPracticeError && (toPay > 0) && <>
            <InfoAlert>Prezzo aggiuntivo da pagare: {formatPrice(toPay)}</InfoAlert>
            {!showPaymentSection && <ContainedButton text={submitText} onClick={onSubmit}/>}
            {paymentLoading && <Spinner/>}
            {
                showPaymentSection && paypalClientId &&
                <Grid style={{marginTop: "20px", textAlign: 'center'}}>
                    <PayPalScriptProvider
                        options={{
                            "client-id": paypalClientId,
                            components: "buttons,funding-eligibility",
                            currency: CURRENCY,
                            'enable-funding': 'paylater,mybank',
                            commit: true,
                        }}
                    >
                        <NewPaymentButtonWrapper
                            currency={CURRENCY}
                            showSpinner={true}
                            amount={toPay / 100}
                            onPaypalAnswered={(val) => {
                                console.info("onPaypalAnswered", val);
                            }}
                            onPaypalPaidOrder={onPaypalPaidOrder}
                            onMarkAsPaymentError={(error) => {
                                console.info("onMarkAsPaymentError", error);
                            }}
                            acceptedPaymentMethods={['card']}
                            onInit={() => {
                            }}
                        />
                    </PayPalScriptProvider>
                </Grid>
            }
            <StatusAlert visible={!!statusAlertPayment} severity={statusAlertPayment?.severity}>
                {statusAlertPayment?.text}
            </StatusAlert>
        </>
    }

    return (
        <>
            <FormContainer>
                <FormContainerCustom>
                    <Content item xs={9}>
                        <FormTitle>
                            <BackLink href="/upselling" variant="body2">
                                <ArrowBack htmlColor="black"/>
                            </BackLink> Upselling pratica #{practice.shortCode}
                        </FormTitle>

                        {renderPriceWithSubmit()}

                        {
                            showPracticeSuccess ?
                                <><SuccessAlert>Congratulazioni! La tua prenotazione è stata modificata con
                                    successo</SuccessAlert>
                                    <br/>
                                    <RetryButton
                                        text="Torna alla lista"
                                        onClick={() => history.replace('/upselling')}
                                    />
                                </>
                                :
                                (showPracticeError ?
                                    <>
                                        <ErrorAlert>{showPracticeErrorMessage}</ErrorAlert>
                                        <br/>
                                        <RetryButton text="Riprova" onClick={() => window.location.reload()}/>
                                    </> :
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="edit-practice-step2-box">
                                                <Title>SERVIZI EXTRA</Title>
                                                {isExtraServicesLoading ?
                                                    <Spinner/> : extraServicesWrapper.map((esw) =>
                                                        <ExtraServiceMobile
                                                            extraservice={esw.val}
                                                            onSelectExtraService={(val, serviceId) => onSelectExtraService(val, esw, serviceId)}
                                                            selectedExtraServices={selectedExtraServices}
                                                            initialExtraServices={initialValues}
                                                            onClearSelection={onClearSelection}
                                                        />)}
                                            </div>
                                        </Grid>
                                    </Grid>)
                        }

                        {renderPriceWithSubmit()}
                    </Content>
                </FormContainerCustom>
            </FormContainer>
        </>
    );
};

export default UpsellPractice;

const FormContainer = styled(Paper)`
  background-color: ${props => props.theme.palette.grey[200]};
`;

const BackLink = styled(Link)`
  align-self: center;
`;

const FormContainerCustom = styled(Grid).attrs({
    container: true,
    spacing: 2,
    alignItems: 'center',
})`
  margin: 4px 0;
`;
const Content = styled(Grid)`
  margin: auto
`;

export const RetryButton = styled(Button).attrs({
    variant: 'contained',
})`
  display: block;
  margin: 32px auto;
`;

const Title = styled(Typography).attrs({
    variant: 'h3',
})``;

const ContainedButton = styled(Button).attrs({
    variant: 'contained',
    color: 'primary',
})`
  color: white;
  display: block;
  margin: 32px auto;
`;
