import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow as Row,
  TableCell,
  Typography,
  Paper,
  MenuItem,
  Button as MuiButton,
  TextField,
} from '@material-ui/core';
import StatusAlert from './StatusAlert';
import {uploadPraticeDocumentFile} from '../utils/api';
import Button from './Button';

const doctType2023A22_LABELS_DICT = {
  1: 'Riepilogo prenotazione',
  2: 'Contratto',
  3: 'Documenti d\'identità',
  4: 'Modulo consenso genitori',
  5: 'Convocazione di viaggio',
  6: 'Documento generico',
  7: 'Attestato'
};

const doctType2023A22_LABELS_OBJ = Object.keys(doctType2023A22_LABELS_DICT).map(
  (item) => (
    {
      'key': parseInt(item),
      'value': doctType2023A22_LABELS_DICT[item]
    }
  )
);

const DocumentDetails = ({
  documents,
  id,
  refresh,
  documentTypologyArray,
  startSignProcess,
  downloadSigned,
  startModuleB,
}) => {
return (
  <>
    <Title>Documenti per questa prenotazione</Title>
    <RenderTable
      documents={documents}
      startSignProcess={startSignProcess}
      downloadSigned={downloadSigned}
      startModuleB={startModuleB}
      />
    <UploadBoxContainer>
      <UploadBox
        id={id}
        refresh={refresh}
        documentTypologyArray={documentTypologyArray}
      />
    </UploadBoxContainer>
  </>
)};

const UploadBox = ({id, refresh, documentTypologyArray}) => {
  //console.log('PRACTICE ID');
  //console.log(id);

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = file => {
    // 4 MB max
    if (file.size > 4 * 1000000) {
      return setShowAlert(true);
    }

    showAlert && setShowAlert(false);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      let encoded = reader.result.replace(
        /^data:(.*;base64,)?/,
        ''
      )
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      setFile({
        fileName: file.name || '',
        fileSize: file.size || 0,
        documentTypologyIndex: '',
        documentTypology: '',
        documentTypologyInfoText: '',
        fileContent: encoded,
      });
      setLoading(false);
    };
  };

  const handleDocumentTypeChange = ({target}) =>
    setFile(file => ({...file,
      documentTypologyIndex: target.value,
      documentTypology: documentTypologyArray[target.value].id,
      documentTypologyInfoText: documentTypologyArray[target.value].infoText,
    }));

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const {
        fileSize,
        documentTypologyIndex,
        documentTypologyInfoText,
        ...rest} = file;
      
      const data = {practice:id, ...rest};

      // console.log("IDDU002");
      // console.log(data);

      await uploadPraticeDocumentFile(data);
      setLoading(false);
      refresh();
      alert("Documento caricato con successo");
      setFile(null)
    } catch (e) {
      setLoading(false);
    }
  };
  const handleCancel = () => setFile(null);

  if (file) {
    const {fileName, fileSize, documentTypologyIndex, documentTypology, documentTypologyInfoText} = file;

    return (
      <UploadContainer>
        <Title>Carica tua documentazione</Title>
        <Typography>File selezionato: {fileName}</Typography>
        <DocumentTypeSelect
          label="Seleziona tipo di documento"
          select
          onChange={handleDocumentTypeChange}
          value={documentTypologyIndex}
        >
          {documentTypologyArray.map((item, index) => (
            <MenuItem value={index} key={index}>
              {item.name}
            </MenuItem>
          ))}
        </DocumentTypeSelect>

        <Typography>
          Dimensione File: {(fileSize / 1000).toFixed(2)} KB
        </Typography>

        <Typography>Ulteriori informazioni: <strong className="word-break">{documentTypologyInfoText}</strong></Typography>

        <ButtonRow>
          <Button
            variant="contained"
            onClick={handleCancel}
            text="ANNULLA"
            size="large"
            style={{minWidth: 150}}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!documentTypology || loading}
            loading={loading}
            onClick={handleSubmit}
            text="CARICA"
            size="large"
            style={{minWidth: 150}}
          />
        </ButtonRow>
      </UploadContainer>
    );
  }

  return (
    <>
      <MuiButton variant="outlined" component="label" color="primary">
        CARICA DOCUMENTO
        <input
          type="file"
          hidden
          onChange={({target}) => handleChange(target.files[0])}
          accept="image/*, .doc, .pdf"
        />
      </MuiButton>
      <StatusAlert visible={showAlert} severity="error">
        Il file selezionato ha dimensioni superiori al massimo supportato
      </StatusAlert>
    </>
  );
};

const RenderTable = ({
  documents,
  startSignProcess,
  downloadSigned,
  startModuleB
}) => {

  const [filtro, setFiltro] = useState("");

  if (!documents.length) {
    return (
      <div style={{textAlign: 'center'}}>
        <i>Nessun documento disponibile per questa prenotazione</i>
      </div>
    );
  }

  const handleFiltroChange = ({target}) => {
    console.log('2023A22 filtro change', target)
    setFiltro(target.value ?? null)
  };

  const filteredDocuments = filtro
    ? documents.filter((document) => document.docType2023A22 === filtro)
    : documents

  return (
    <Container>
      <FilterRow>
        <FilterTypography>
          Seleziona Filtro
        </FilterTypography>
        <FilterSelect
          label=""
          select
          onChange={handleFiltroChange}
          value={filtro}
        >
          <MenuItem value="" key={0}>Tutte le tipologie</MenuItem>
          {doctType2023A22_LABELS_OBJ.map((item, index) => (
            <MenuItem value={item.key} key={index+1}>
              {item.value}
            </MenuItem>
          ))}

        </FilterSelect>
      </FilterRow>
      <Table className="tb_documents">
        <Head>
          <Row>
            <Cell>Data</Cell>
            <Cell>Tipologia</Cell>
            <Cell>Nome Documento</Cell>
            <Cell>Generato/Caricato da</Cell>
            <Cell>Link File</Cell>
          </Row>
        </Head>

        <Body>
          {filteredDocuments
            .sort(
              (a, b) =>
              Number(new Date(b.creationDate)) - Number(new Date(a.creationDate))
            )
            .map((doc, index) => (
            <DocumentRow
              key={index}
              doc={doc}
              startSignProcess={startSignProcess}
              downloadSigned={downloadSigned}
              startModuleB={startModuleB}
            />
          ))}
        </Body>
      </Table>
    </Container>
  );
};

DocumentDetails.propTypes = {
  documents: PropTypes.array,
};

export default DocumentDetails;

const DocumentRow = ({doc, startSignProcess, downloadSigned, startModuleB}) => {
  let actions = null;

  if (doc.isSignable === false) {
    actions = (
      <DocLink
        href={
          doc.docType === 'Generato'
            ? `${doc.docUrl}_${doc.id}.pdf`
            : doc.docUrl
        }
        text="Apri File"
      />
    );
  } else if (doc.docType === 'ModuloB') {
    actions = (
      <>
        {doc.isSigned ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => downloadSigned(doc.id)}
            text="Apri documento firmato"
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => startModuleB(doc.id)}
            text="Compila"
          />
        )}
      </>
    );
  } else {
    actions = (
      <>
        {doc.isSigned ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => downloadSigned(doc.id)}
            text="Apri documento firmato"
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => startSignProcess(doc.id)}
            text="Apri e firma online"
          />
        )}
      </>
    );
  }

  return (
    <Row>
      <Cell>{formatDate(new Date(doc.creationDate), 'dd/MM/yyyy HH:mm')}</Cell>
      <Cell>{doc.docType2023A22 === null ? null : doctType2023A22_LABELS_DICT[doc.docType2023A22]}</Cell>
      <Cell>
        {doc.isSignable && doc.isSigned === true && (
          <React.Fragment>
            DOCUMENTO FIRMATO <br />
          </React.Fragment>
        )}
        {doc.title}
      </Cell>
      <Cell>{doc.memberUploaded ? 'Cliente' : 'Giocamondo'}</Cell>
      <Cell className="flex">{actions}</Cell>
    </Row>
  );
};

const Container = styled(TableContainer).attrs({
  component: Paper,
})``;

const Title = styled(Typography).attrs({variant: 'h3'})`
  display: block;
  margin: 24px 0;
`;

const Head = styled(TableHead)`
  width: 100%;
  background-color: ${props => props.theme.palette.table.head};
  color: ${props => props.theme.palette.text.primary};
`;

const Body = styled(TableBody)`
  color: ${props => props.theme.palette.text.secondary};
`;

const Cell = styled(TableCell).attrs({
  align: 'center',
})`
  color: inherit;
`;

const DocLink = styled(Button).attrs({
  variant: 'contained',
  target: '_blank',
})``;

const UploadBoxContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 32px;
`;

const UploadContainer = styled(Paper)`
  padding: 0 20px 20px;
  width: 50%;
  min-width: 500px;
`;

const DocumentTypeSelect = styled(TextField)`
  margin: 16px 0 28px;
  min-width: 300px;
`;

const FilterRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const FilterTypography = styled(Typography)`
  color: #f3b117;
  margin: 0px 8px 0px 0px;
`;

const FilterSelect = styled(TextField)`
  margin: 0px 8px 0px 0px;
  min-width: 190px;

  color: #f3b117;
  border: 1px solid rgba(243, 177, 23, 0.5);
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  & > button:first-child {
    margin-right: 8px !important;
  }
  & > button:last-child {
    margin-left: 8px !important;
  }
`;
