import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Grid,
  TextField as MaterialTextField,
} from '@material-ui/core';
import {OnChange} from 'react-final-form-listeners';

import {AutocompleteField, TextField, RadioButtons} from './';
import Button from '../Button';
import {FormSectionTitle} from '../../utils/commonStyledComponents';
import FormErrorsPopup from './FormErrorsPopup';
import {validateFiscalCode} from './validation';
import {calculateFiscalCode} from '../../utils/api';

const ContactInfo = ({values, cities, form}) => {
  const [loadingFiscalCode, setLoadingFiscalCode] = useState(false);
  const [fiscalCodeErrors, setFiscalCodeErrors] = useState([]);

  const setFiscalCode = useCallback(
    async (form, values) => {
      setLoadingFiscalCode(true);

      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        birthDate: values.birthDate,
        birthPlace: values.birthPlace,
      };

      const errors = validateFiscalCode(data);

      if (errors) {
        setFiscalCodeErrors(Object.values(errors));
        setLoadingFiscalCode(false);
        return;
      }

      try {
        const fiscalCode = await calculateFiscalCode(data);
        form.mutators.setFiscalCode(fiscalCode);

        setLoadingFiscalCode(false);
      } catch (e) {
        const responseErrors = e.response?.data?.errors || [];
        const errors = [];

        for (let error in responseErrors) {
          errors.push(responseErrors[error][0]);
        }

        setFiscalCodeErrors(errors);
        setLoadingFiscalCode(false);
      }
    },
    [setLoadingFiscalCode, setFiscalCodeErrors]
  );

  return (
    <>
      <FormErrorsPopup
        title="Dati necessari per il calcolo del codice:"
        errors={fiscalCodeErrors}
        close={() => setFiscalCodeErrors([])}
      />

      {/* reset fields when user selects different nationality */}
      <OnChange
        name="nationality"
        children={value => {
          if (value === 'Italiana') {
            form.mutators.setField(null, 'foreignBirthCity');
            form.mutators.setField(null, 'birthNation');
          } else {
            form.mutators.setField(null, 'birthPlace');
          }
        }}
      />

      {/* set values of cap and province based on this value */}
      <OnChange
        name="city"
        children={value => {
          const city = cities.find(({name}) => name === value);

          const cap = city ? city.cap.split(',')[0] : '';
          const prov = city ? city.car : '';

          form.mutators.setField(cap, 'cap');
          form.mutators.setField(prov, 'province');
        }}
      />

      {/* Personal infomation */}
      <GridRow>
        <Grid item sm xs={12}>
          <Field name="firstName" label="Nome" />
        </Grid>

        <Grid item sm xs={12}>
          <Field name="lastName" label="Cognome" />
        </Grid>

        <Grid item sm="auto" xs={12}>
          <RadioButtons
            name="gender"
            options={[
              {value: 'F', label: 'Donna'},
              {value: 'Z', label: 'Uomo'},
            ]}
          />
        </Grid>
      </GridRow>
      <GridRow>
        <Grid item sm={4} xs={12}>
          <Field select name="nationality" label="Nazione di nascita">
            <MenuItem value="Italiana">Italiana</MenuItem>
            <MenuItem value="Estera">Estera</MenuItem>
          </Field>
        </Grid>

        {values.nationality === 'Italiana' ? (
          <>
            <Grid item sm={6} xs={10}>
              <AutocompleteField
                options={cities.map(city => city.name)}
                getOptionLabel={city => city}
                name="birthPlace"
                label="Nato a"
                loading={!cities.length}
              />
            </Grid>

            <Grid item sm={2} xs={2}>
              <MaterialTextField
                value={
                  cities.find(({name}) => name === values.birthPlace)?.car || ''
                }
                label="Prov"
                disabled
                InputLabelProps={{shrink: true}}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={4} xs={12}>
              <Field name="birthNation" label="Nazione" />
            </Grid>

            <Grid item sm={4} xs={12}>
              <Field name="foreignBirthCity" label="Città di nascita" />
            </Grid>
          </>
        )}
      </GridRow>
      <GridRow>
        <Grid item sm={4} xs={12}>
          <DateField name="birthDate" label="Data di nascita" />
        </Grid>

        <Grid item sm={'auto'} xs={5}>
          <FiscalCodeButton
            text="Calcola CF"
            loading={loadingFiscalCode}
            onClick={() => setFiscalCode(form, values)}
            disabled={values.nationality !== 'Italiana' || loadingFiscalCode}
          />
        </Grid>

        <Grid item sm xs>
          <Field name="fiscalCode" label="Codice Fiscale" />
        </Grid>
      </GridRow>
      {/* ContactInformation */}
      <GridRow>
        <Grid item sm xs={12}>
          <Field name="email1" label="Email (1)" type="email" />
        </Grid>

        <Grid item sm xs={12}>
          <Field name="email2" label="Email (2)" type="email" />
        </Grid>
      </GridRow>
      <GridRow>
        <Grid item sm={4} xs={12}>
          <Field name="phone1" label="Telefono (1)" />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Field name="phone2" label="Telefono (2)" />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Field name="mobilePhone" label="Cellulare" />
        </Grid>
      </GridRow>
      {/* Address Information */}
      <FormSectionTitle>Residenza</FormSectionTitle>
      <GridRow>
        <Grid item sm={4} xs={12}>
          <Field name="address" label="Via/Piazza" />
        </Grid>

        <Grid item sm={4} xs={12}>
          <AutocompleteField
            options={cities.map(city => city.name)}
            getOptionLabel={city => city}
            name="city"
            label="Città"
            loading={!cities.length}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Field name="cap" label="CAP" />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Field name="province" label="Prov" />
        </Grid>
      </GridRow>
    </>
  );
};

ContactInfo.propTypes = {
  values: PropTypes.object,
  form: PropTypes.object,
  cities: PropTypes.array,
};

export default ContactInfo;

const GridRow = styled(Grid).attrs({
  container: true,
  spacing: 2,
  alignItems: 'center',
})`
  margin: 4px 0;
`;

const Field = styled(TextField).attrs({fullWidth: true})``;

const DateField = styled(Field).attrs({
  type: 'date',
  InputLabelProps: {
    shrink: true,
  },
})``;

const FiscalCodeButton = styled(Button).attrs({
  variant: 'contained',
  size: 'small',
})``;
