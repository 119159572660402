import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {Dialog, DialogContent, DialogTitle, Grid, Paper, Typography} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import UpdateIcon from '@material-ui/icons/Update';

import {ErrorMessage, TextField, PasswordField} from './';
import Button from '../Button';
import StatusAlert from '../StatusAlert';
import {validatePasswordChange} from './validation';
import {useUser} from '../../utils/UserSession';
import {deleteAccount, updateMemberPassword, updateMemberUsername} from '../../utils/api';
import ToggleEditField from "./ToggleEditField";
import Logout from "../../screens/Logout";
import {useHistory} from "react-router-dom";
import {DeleteForever, Remove} from "@material-ui/icons";
import {useAlert} from "../../utils/AlertProvider";

const ChangeUserPasswordAndDeleteAccount = () => {
    const {user} = useUser();
    const [showAlert, setShowAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState(null);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [onDeleting, setOnDeleting] = useState(false);
    const history = useHistory();
    const showGlobalAlert = useAlert();


    const onDeleteAccount = async () => {
        try {
            setOnDeleting(true);
            await deleteAccount();
            onHandleDeleteAccountDialog(false);
            setOnDeleting(false);
            showGlobalAlert({message: 'Il tuo account è stato eliminato con successo'});
            //Logout
            history.replace('/logout');
        } catch (e) {
            setErrorAlert(true);
            setTimeout(() => {
                onHandleDeleteAccountDialog(false);
                setErrorAlert(false);
            }, 4000);
        }
    }
    const onHandleDeleteAccountDialog = val => setOpenDialog(val)

    const onSubmit = useCallback(async data => {
        try {
            const errors = validatePasswordChange(data);
            if (errors) {
                return errors;
            }
            const {username, confirmNewPassword, ...dataToSubmit} = data;
            await updateMemberPassword(dataToSubmit);

            setShowAlertMessage("Password aggiornata con successo");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setShowAlertMessage(null);
            }, 3000);
        } catch (e) {
            const {data} = e.response || {};

            if (data.errors) {
                return data.errors;
            }

            return {
                [FORM_ERROR]:
                data.message ||
                'Si è verificato un errore nella comunicazione con il server',
            };
        }
    }, []);

    const onUpdateUsername = useCallback(async newUsername => {
        try {
            await updateMemberUsername({username: newUsername});

           showGlobalAlert({
               message: 'Username aggiornata. Fai nuovamente l’accesso con la nuova username. Ricorda che la mail per il recupero password è quella che trovi accanto nel campo "Email (1)"',
               centered: true,
               duration: 6000
           })
            setTimeout(() => {
                //Logout
                history.replace('/logout');
            }, 4000);
        } catch (e) {
            setUsernameErrorMessage('Il salvataggio non è andato a buon fine. Il formato utilizzato non è corretto e/o l’username scelto è già in uso per un’altra utenza');
            setTimeout(() => {
                setUsernameErrorMessage(null);
            }, 10000);
            throw Error();
        }
    }, []);

    return (
        <>
            <Grid flexDirection={'column'}>
                <Container>
                    <Form
                        onSubmit={onSubmit}
                        render={({form, handleSubmit, submitting, submitError}) => (
                            <StyledForm
                                onSubmit={event => {
                                    handleSubmit(event).then(data => data || form.reset());
                                }}
                            >
                                <Row>
                                    <LockIcon/>
                                    <Title>USERNAME</Title>
                                </Row>
                                <Subtitle variant="caption">
                                    Aggiorna username/password<br/>
                                    nb. Il nuovo username deve iniziare con una lettera, non può contenere spazi e deve avere una lunghezza minima di 5 caratteri
                                </Subtitle>

                                {!submitting && submitError && (
                                    <ErrorMessage>{submitError}</ErrorMessage>
                                )}
                                {usernameErrorMessage && (
                                    <ErrorMessage>{usernameErrorMessage}</ErrorMessage>
                                )}

                                <ToggleEditField
                                    value={user.username}
                                    updateValue={onUpdateUsername}
                                    name={'username'}
                                    fullWidth
                                    avoidExternalFocus
                                />
                                <br/>
                                <Row>
                                    <LockIcon/>
                                    <Title>PASSWORD</Title>
                                </Row>
                                <Password name="oldPassword" label="Password Attuale" fullWidth/>
                                <Password name="newPassword" label="Nuova Password" fullWidth/>
                                <Password
                                    name="confirmNewPassword"
                                    label="Conferma Nuova Password"
                                    fullWidth
                                />

                                <SubmitButton
                                    text="Aggiorna"
                                    loading={submitting}
                                    disabled={submitting}
                                />

                                <StatusAlert visible={showAlert}>
                                    {showAlertMessage}
                                </StatusAlert>
                            </StyledForm>
                        )}
                    />
                </Container>
                <Container>
                    <Grid flexDirection={'column'}>
                        <Row>
                            <DeleteForever/>
                            <Title>ELIMINAZIONE ACCOUNT</Title>
                        </Row>
                        <Subtitle variant="caption">Puoi eliminare autonomamente il tuo account rinunciando ad accedere
                            all’area riservata “Giocamondo Club”.
                            Nel caso in cui non ci siano prenotazioni collegate al tuo account sarà eliminata anche la
                            tua anagrafica e i tuoi dati in essa contenuti.
                            Nel caso in cui ci siano prenotazioni nei seguenti stati “Confermato”, “Opzionato”,
                            “Annullato” elimineremo l’account di accesso all’area riservata “Giocamondo Club”,
                            cancelleremo i dati di contatto e imposteremo i consensi forniti all’interno del nostro
                            sistema gestionale su “Non Acconsento”.</Subtitle>

                        <CustomButtonOutlined
                            text="Elimina il tuo account"
                            onClick={() => onHandleDeleteAccountDialog(true)}
                        />

                        <StatusAlert visible={errorAlert} severity="error">
                            Account eliminato!
                        </StatusAlert>
                    </Grid>
                </Container>
            </Grid>
            <Dialog open={openDialog} onBackdropClick={() => onHandleDeleteAccountDialog(false)}>
                <DialogContent>
                    <Text>
                        Ti ricordiamo che non potrai più accedere all’area riservata rinunciando anche ai punti e al tuo
                        cluster di appartenenza.
                        Non potrai più modificare autonomamente i tuoi dati anagrafici né vedere autonomamente eventuali
                        informazioni,
                        pagamenti, documenti delle tue prenotazioni con Giocamondo. Vuoi comunque eliminare l’account?
                    </Text>
                    <ButtonRow>
                        <CustomButton
                            text="Voglio mantenere l'account"
                            onClick={() => onHandleDeleteAccountDialog(false)}
                        />
                        <CustomButtonOutlined
                            text="Confermo l'eliminazione dell'account"
                            onClick={onDeleteAccount}
                            loading={onDeleting}
                        />
                    </ButtonRow>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ChangeUserPasswordAndDeleteAccount;

const Container = styled(Paper)`
  display: block;
  margin-bottom: 32px;
  width: 300px;
  padding: 32px;
  background-color: ${props => props.theme.palette.grey[100]};
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(Typography).attrs({variant: 'h5'})`
  font-weight: 700;
  margin-left: 4px;
`;

const Subtitle = styled(Typography).attrs({variant: 'caption'})`
  margin-bottom: 32px;
  color: grey;
`;

const SubmitButton = styled(Button).attrs({
    type: 'submit',
    variant: 'outlined',
    color: 'primary',
})`
  margin: 0;
  margin-top: 22px;
  align-self: center;
`;

const CustomButtonOutlined = styled(Button).attrs({
    variant: 'outlined',
    color: 'primary',
})`
  margin: 0 auto;
  margin-top: 22px;
  align-self: center;
`;

const CustomButton = styled(Button).attrs({
    variant: 'contained',
    color: 'primary',
})`
  margin: 0 auto;
  margin-top: 22px;
  align-self: center;
`;
const Text = styled(Typography).attrs({variant: 'body1', component: 'span'})``;

const MyText = styled(TextField)`
  margin: 12px 0;
`;

const Password = styled(PasswordField)`
  margin: 12px 0;
`;
