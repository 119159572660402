import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {useHistory} from 'react-router-dom';
import {Link, Grid, Typography, Container} from '@material-ui/core';

import Logo from '../components/LoginSectionLogo';
import {TextField, ErrorMessage, PasswordField} from '../components/Form';
import {SecondarySubmitButton} from '../utils/commonStyledComponents';
import {login, fetchUser} from '../utils/api';
import {bootIntercom} from '../utils/intercom';
import {useUser} from '../utils/UserSession';
import {validateLogin} from '../components/Form/validation';

export default function Login() {
  const {setUser, setToken} = useUser();
  const history = useHistory();

  const onSubmit = useCallback(
    async data => {
      try {
        const errors = validateLogin(data);
        if (errors) {
          return errors;
        }

        const tokenResponse = await login(data);
        setToken(tokenResponse.token);

        const user = await fetchUser();
        setUser(user);
        //bootIntercom(user);
        localStorage.removeItem('sectionSelected')
        history.push('/');
      } catch (e) {
        return {
          [FORM_ERROR]:
            e.response?.data?.message || 'Le credenziali non sono corrette',
        };
      }
    },
    [history, setToken, setUser]
  );

  return (
    <div>
      <Logo />
      <StyledContainer>
        <FormContainer>
          <FormTitle>Accedi</FormTitle>
          <Form
            onSubmit={onSubmit}
            render={({handleSubmit, submitting, submitError}) => (
              <StyledForm noValidate onSubmit={handleSubmit}>
                {!submitting && submitError && (
                  <ErrorMessage>{submitError}</ErrorMessage>
                )}

                <TextField
                  name="username"
                  margin="normal"
                  required
                  fullWidth
                  label="Username"
                  autoComplete="username"
                  autoFocus
                />
                <Grid item xs>
                  <Link href="/username-recovery" variant="body2">
                    Hai dimenticato l'username?
                  </Link>
                </Grid>
                <PasswordField
                  name="password"
                  margin="normal"
                  required
                  fullWidth
                  label="Password"
                  autoComplete="current-password"
                />
                <SecondarySubmitButton
                  text="Accedi"
                  disabled={submitting}
                  loading={submitting}
                  fullWidth
                />

                <Grid container>
                  <Grid item xs>
                    <Link href="/password-recovery" variant="body2">
                      Password dimenticata?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/register" variant="body2">
                      Non hai un account? Registrati
                    </Link>
                  </Grid>

                  <Grid item xs={12} className="simple-link">
                    <a href="https://giocamondo.it/istruzioni-area-giocamondo-club/" target="_blank">
                    Non riesci ad accedere? Clicca qui
                    </a>
                  </Grid>

                </Grid>
              </StyledForm>
            )}
          />
        </FormContainer>
      </StyledContainer>
    </div>
  );
}

const StyledContainer = styled(Container).attrs({
  component: 'main',
  maxWidth: 'xs',
})`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled.form`
  width: 100%;
  margin-top: 8px;
`;

const FormTitle = styled(Typography).attrs({
  variant: 'h2',
  align: 'center',
  color: 'textSecondary',
})`
  margin-top: 32px;
`;
